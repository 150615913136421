import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import Axios from "axios";
import API_URL from "../Api/ApiUrl";
import Button from "../General/Button";
import "../../App.css";

export class VerifyPayment extends Component {
  static displayName = VerifyPayment.name;

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      paymentSuccess: false,
      refId: "",
    };

    this.onContinueSignup = this.onContinueSignup.bind(this);
  }

  componentDidMount() {
    const payId =200;

    if (payId) {
      Axios.post(API_URL + `payment/${payId}`, {
        id: payId,
      })
        .then((res) => {
          console.log(res)
          const payStatus = Number(res.data.payStatus);
          const isSuccess = payStatus === 100 || payStatus === 101;
          const refId = res.data?.refid ?? "";

          if (isSuccess) {
            localStorage.removeItem("payId");
          }
          this.setState({ loading: false, paymentSuccess: isSuccess, refId });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  onPaymentCall() {
    const userId = localStorage.getItem("userId");

    if (userId) {
      Axios.post(API_URL + `repayment/${userId}`, {
        id: userId,
      })
        .then((res) => {
          const authority = res.data.authority;
          const payId = res.data.payId;

          localStorage.setItem("payId", payId);

          window.location = `https://www.zarinpal.com/pg/StartPay/${authority}`;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  onContinueSignup() {
    const recordId = localStorage.getItem("merchantId");
    const utype = localStorage.getItem("utype");
    const regType = localStorage.getItem("regType");
    const sellerCount = localStorage.getItem("sellerCount") ?? 0;
    const signupType =
      utype === "merchant" ? 1 : utype === "provider" ? 2 : null;

    const pathname = regType === "2" ? "/confirmCompany" : "/confirmPersonal";

    this.props.history.push({
      pathname,
      signupType,
      recordId,
      action: Number(sellerCount) > 0 ? "add" : "none",
    });
  }

  render() {
    return (
      <div className="outerCntnt">
        <div
          className="centerContent"
          style={{ width: 1000, textAlign: "right", paddingTop: 10 }}
        >
          {/* <div style={{ float: "right", width: 500, transform: "scale(0.92)" }}>
            <Card
              className="cardStyle inner-pages-card"
              style={{ padding: "5rem 3rem" }}
            >
              <CardBody style={{ textAlign: "center" }}>
                {this.state.loading ? (
                  <h2 style={{ direction: "rtl" }}>لطفا منتظر بمانید...</h2>
                ) : this.state.paymentSuccess ? (
                  <>
                    <img
                      src={require("../../assets/images/success.png")}
                      alt="success"
                      style={{ marginBottom: "2rem" }}
                    />

                    <p style={{ fontSize: 14, textAlign: "center" }}>
                      پرداخت شما موفق بود. <br />
                      کد رهگیری:‌ {this.state.refId}
                    </p>

                    <hr />

                    <Button
                      text="ادامه ثبت نام"
                      type="button"
                      classes="btnBlueNormal"
                      onClickFn={this.onContinueSignup}
                    />
                  </>
                ) : (
                  <>
                    <img
                      src={require("../../assets/images/failed.png")}
                      alt="failed"
                      style={{ marginBottom: "2rem" }}
                    />

                    <p style={{ fontSize: 14, textAlign: "center" }}>
                      پرداخت شما ناموفق بود. لطفا مجددا تلاش کنید.
                    </p>

                    <hr />

                    <Button
                      text="پرداخت"
                      type="button"
                      classes="btnBlueNormal"
                      onClickFn={this.onPaymentCall}
                    />
                  </>
                )}
              </CardBody>
            </Card>
          </div> */}
          <Button
                      text="ادامه ثبت نام"
                      type="button"
                      classes="btnBlueNormal"
                      onClickFn={this.onContinueSignup}
                    />
        </div>
      </div>
    );
  }
}
