import React, { Component } from "react";
import Axios from "axios";
import API_URL from "../Api/ApiUrl";
import { Card, CardBody } from "reactstrap";
import { ValidatorForm } from "react-form-validator-core";
import TextValidator from "../General/TextValidator";
import Button from "../General/Button";
import Icon from "../Icons/Icon";
import "../../App.css";

export class Profile extends Component {
  static displayName = Profile.name;

  form;
  password;
  password2;
  constructor(props) {
    super(props);

    this.state = {
      form: {
        password: "",
        password2: "",
        userName: "",
      },
      isSaved: false,
      btnStatus: "disabled",
      btnClassName: "btnBlueDisabled",
      resultMessage: "",
      nameFamily: "",
      roleTitle: "",
      userName: "",
      avatarSource: null,
      avatarBase64: null,
      imgSrc: null,
      selectedFile: null,
      resultMessage: "",
      showLoading: false,
      comparePswrd: false,
      passchckbox: "",
    };

    this.form = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.loadUserInfo();
    Axios.get(
      API_URL +
        "Avatar/getUserAvatar/" +
        window.localStorage.getItem("userCode")
    )
      .then((response) => {
        var avtrBase64 = response.data.image;
        this.setState({ avatarBase64: avtrBase64 });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  loadUserInfo() {
    this.setState({ nameFamily: window.localStorage.getItem("nameFamily") });
    this.setState({ userName: window.localStorage.getItem("userName") });
    this.setState({ roleTitle: window.localStorage.getItem("roleTitle") });
  }

  handleChange(event) {
    this.setState({ comparePswrd: false });
    this.setState({ resultMessage: "" });
    this.setState({
      form: {
        ...this.state.form,
        [event.target.id]: event.target.value,
      },
    });
  }

  changeUserAvatar = (base64) => {
    Axios({
      method: "post",
      url: API_URL + "Avatar/changeAvatar",
      timeout: 8000, // 8 seconds timeout
      headers: { "Content-Type": "application/json" },
      data: {
        Id: 0,
        UserCode: localStorage.getItem("userCode"),
        Image: base64,
      },
    }).catch((error) => console.error(error));
  };

  async handleSubmit() {
    var pswrd1 = this.state.form.password;
    var pswrd2 = this.state.form.password2;

    if (!pswrd1 && !pswrd2) {
      return;
    }

    if (pswrd1 != pswrd2) {
      this.setState({ comparePswrd: true });
      return;
    }

    const login = {
      Username: this.state.userName,
      Password: this.state.form.password,
    };

    Axios.put(API_URL + "User/changePassword", login)
      .then((res) => {
        this.setState({
          form: {
            password: "",
            password2: "",
          },
        });
        this.setState({ passchckbox: "" });
        this.setState({ btnStatus: "disabled" });
        this.setState({ btnClassName: "btnBlueDisabled" });
        this.setState({ resultMessage: "تغییر رمز با موفقیت انجام شد" });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (e) => reject(e);
    });

  onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = this.handleFile.bind(this);
      reader.onerror = () => {};
    }
  };

  handleFile(event) {
    var binaryString = event.target.result;
    this.setState({ avatarBase64: binaryString });
    this.changeUserAvatar(binaryString);
  }

  goToBack() {
    var role_id = localStorage.getItem("roleId");
    switch (role_id) {
      case "2":
        this.props.history.push("/merchant");
        break;
      case "3":
        this.props.history.push("/carrier");
        break;
      case "4":
        this.props.history.push("/forwarder");
        break;
      case "5":
      case "6":
        this.props.history.push("/merchant");
        break;
    }
  }

  onCheckBoxChanged(e) {
    let isChecked = e.target.checked;
    if (isChecked) {
      this.setState({ passchckbox: "checked" });
      this.setState({ btnStatus: "" });
      this.setState({ btnClassName: "btnBlueNormal" });
    } else {
      this.setState({ passchckbox: "" });
      this.setState({ btnStatus: "disabled" });
      this.setState({ btnClassName: "btnBlueDisabled" });
    }
    this.setState({ resultMessage: "" });
  }

  render() {
    return (
      <div className="outerCntnt">
        <div className="innerCntnt">
          <div style={{ width: "100%", textAlign: "right", paddingTop: 0 }}>
            <div
              style={{
                float: "right",
                width: 480,
                paddingLeft: 15,
                transform: "scale(0.86)",
              }}
            >
              <Card
                className="cardStyle auth-card"
                style={{ borderRadius: 20 }}
              >
                <CardBody>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {this.state.avatarBase64 ? (
                      <img
                        className="avatarImage"
                        src={`${this.state.avatarBase64}`}
                      />
                    ) : (
                      <span className="avatar-default big">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 64.522 70"
                          width="64.522"
                          height="70"
                        >
                          <g opacity=".21">
                            <path
                              class="b"
                              fill="#fff"
                              d="M18.158 67.237a15.211 15.211 0 01-14.037-9.1 15.023 15.023 0 012.505-16.284 34.3 34.3 0 0151.211.037 15.012 15.012 0 012.505 16.284 15.211 15.211 0 01-14.037 9.1H18.158z"
                            />
                            <path d="M32.232 33.158a31.5 31.5 0 0123.542 10.537c7.147 8.032 1.326 20.742-9.468 20.742H18.158c-10.795 0-16.653-12.711-9.468-20.742a31.5 31.5 0 0123.542-10.537m0-5.526A37.038 37.038 0 004.564 40.048 17.659 17.659 0 001.617 59.28a17.97 17.97 0 0016.579 10.721h28.146A18.008 18.008 0 0062.921 59.28a17.728 17.728 0 00-2.947-19.232 37.314 37.314 0 00-27.742-12.416z" />
                            <g>
                              <path
                                class="b"
                                fill="#fff"
                                d="M32.232 39.605a18.421 18.421 0 1118.421-18.421 18.428 18.428 0 01-18.421 18.421z"
                              />
                              <path d="M32.232 5.526a15.658 15.658 0 11-15.658 15.658A15.645 15.645 0 0132.232 5.526m0-5.526a21.184 21.184 0 1021.184 21.184A21.211 21.211 0 0032.232 0z" />
                            </g>
                          </g>
                        </svg>
                      </span>
                    )}
                  </div>
                  <div style={{ marginBottom: "0.7rem", textAlign: "center" }}>
                    <label
                      htmlFor="myImge"
                      style={{
                        color: "#0000ee",
                        fontSize: "13px",
                        marginTop: 10,
                        marginBottom: 10,
                        cursor: "pointer",
                      }}
                    >
                      انتخاب عکس
                    </label>
                    <input
                      id="myImge"
                      name="myImge"
                      style={{ display: "none" }}
                      type={"file"}
                      onChange={this.onImageChange}
                    />
                  </div>
                  <div className="profile-field">
                    <span className="profile-field-label">
                      نام و نام خانوادگی:
                    </span>
                    <p className="profile-field-value">
                      {this.state.nameFamily}
                    </p>
                  </div>
                  <div className="profile-field">
                    <span className="profile-field-label">نقش:</span>
                    <p className="profile-field-value">
                      {this.state.roleTitle}
                    </p>
                  </div>
                  <div className="profile-field">
                    <span className="profile-field-label">نام کاربری:</span>
                    <p className="profile-field-value">{this.state.userName}</p>
                  </div>
                  <div className="welButn" style={{ marginTop: "0.5rem" }}>
                    <label
                      style={{
                        alignSelf: "flex-end",
                        fontWeight: "bold",
                        fontSize: "15px",
                        lineHeight: "25px",
                        color: "#43425d",
                      }}
                    >
                      بازنشانی رمز عبور
                    </label>
                    <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
                      <div
                        className="label-with-icon"
                        style={{ marginBottom: 5 }}
                      >
                        <span>
                          رمز عبور
                          <Icon icon="lock" size={15} />
                        </span>
                      </div>
                      <TextValidator
                        type="password"
                        classes="textInputEn"
                        ref={this.password}
                        name="password"
                        id="password"
                        placeholder="*****"
                        value={this.state.form.password}
                        handleChange={this.handleChange}
                        validators={["required"]}
                        errorMessages={["کلمه عبور را وارد کنید"]}
                      />
                      <div style={{ width: "100%", height: 15 }} />
                      <div
                        className="label-with-icon"
                        style={{ marginBottom: 5 }}
                      >
                        <span>
                          تکرار رمز عبور
                          <Icon icon="lock" size={15} />
                        </span>
                      </div>
                      <TextValidator
                        type="password"
                        classes="textInputEn"
                        ref={this.password2}
                        name="password2"
                        id="password2"
                        placeholder="*****"
                        value={this.state.form.password2}
                        handleChange={this.handleChange}
                        validators={["required"]}
                        errorMessages={["تکرار کلمه عبور را وارد کنید"]}
                      />
                      {this.state.resultMessage ? (
                        <div
                          style={{
                            height: "auto",
                            padding: 12,
                            paddingBottom: 0,
                            textAlign: "center",
                          }}
                        >
                          <span
                            style={{
                              verticalAlign: "middle",
                              fontSize: 14,
                              fontWeight: "bold",
                              color: "#008800",
                            }}
                          >
                            {this.state.resultMessage}
                          </span>
                        </div>
                      ) : null}
                      <div style={{ width: "100%", height: 35 }} />
                      <div className="m-t-30 text-center">
                        <Button
                          text="ثبت"
                          type="submit"
                          classes="btnBlueNormal"
                          disabled={false}
                        />
                      </div>
                      <div style={{ width: 100, height: 10 }}></div>
                      <div className="m-t-30 text-center">
                        <Button
                          text="بازگشت"
                          type="button"
                          classes="btnOutLineNormal"
                          onClickFn={this.goToBack.bind(this)}
                        />
                      </div>
                    </ValidatorForm>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
