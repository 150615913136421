import React, { Component } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import Button from "../General/Button";
import { CCol, CRow, CContainer } from "@coreui/react";
import "../../App.css";

export class Messages extends Component {
  static displayName = Messages.name;

  constructor(props) {
    super(props);

    this.state = {
      messageList: [],
    };
  }

  componentDidMount() {
    this.getMessageList();
  }

  getMessageList() {
    //by user Id
  }

  goToBack() {
    var role_id = localStorage.getItem("roleId");
    switch (role_id) {
      case "2":
        this.props.history.push("/merchant");
        break;
      case "3":
        this.props.history.push("/carrier");
        break;
      case "4":
        this.props.history.push("/forwarder");
        break;
      case "5":
      case "6":
        this.props.history.push("/merchant");
        break;
    }
  }

  render() {
    const { messageList } = this.state;
    const ColoredLine = ({ color, width }) => (
      <hr
        style={{
          color: color,
          backgroundColor: color,
          width: width,
          height: 1,
        }}
      />
    );
    return (
      <div className="outerCntnt">
        <div className="innerCntnt">
          <div style={{ width: "100%", textAlign: "right", paddingTop: 25 }}>
            <div
              style={{
                float: "right",
                width: 480,
                paddingLeft: 15,
                transform: "scale(0.86)",
              }}
            >
              <Card className="cardStyle auth-card">
                <CardHeader
                  style={{
                    padding: 0,
                    borderBottom: "0px",
                    marginBottom: "1rem",
                    marginRight: 20,
                  }}
                >
                  <div
                    style={{
                      width: "auto",
                      height: 20,
                      float: "right",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: 15,
                        lineHeight: "25px",
                        color: " #272730",
                      }}
                    >
                      فهرست پیام ها
                    </span>
                  </div>
                </CardHeader>
                <CardBody style={{ paddingBottom: "1rem" }}>
                  <div className="notifications-list">
                    <div className="notification-item">
                      <span className="date">1400/05/05</span>
                      <p>
                        لورم ایپسوم، نام متنی است که به‌‌عنوان نگهدارنده و
                        نشان‌دهنده‌ی مکان متن در طراحی‌های گرافیکی استفاده
                        می‌شود
                      </p>
                    </div>
                    <div className="notification-item">
                      <span className="date">1400/05/05</span>
                      <p>
                        لورم ایپسوم، نام متنی است که به‌‌عنوان نگهدارنده و
                        نشان‌دهنده‌ی مکان متن در طراحی‌های گرافیکی استفاده
                        می‌شود
                      </p>
                    </div>
                    <div className="notification-item">
                      <span className="date">1400/05/05</span>
                      <p>
                        لورم ایپسوم، نام متنی است که به‌‌عنوان نگهدارنده و
                        نشان‌دهنده‌ی مکان متن در طراحی‌های گرافیکی استفاده
                        می‌شود
                      </p>
                    </div>
                    <div className="notification-item">
                      <span className="date">1400/05/05</span>
                      <p>
                        لورم ایپسوم، نام متنی است که به‌‌عنوان نگهدارنده و
                        نشان‌دهنده‌ی مکان متن در طراحی‌های گرافیکی استفاده
                        می‌شود
                      </p>
                    </div>
                    <div className="notification-item">
                      <span className="date">1400/05/05</span>
                      <p>
                        لورم ایپسوم، نام متنی است که به‌‌عنوان نگهدارنده و
                        نشان‌دهنده‌ی مکان متن در طراحی‌های گرافیکی استفاده
                        می‌شود
                      </p>
                    </div>
                    <div className="notification-item">
                      <span className="date">1400/05/05</span>
                      <p>
                        لورم ایپسوم، نام متنی است که به‌‌عنوان نگهدارنده و
                        نشان‌دهنده‌ی مکان متن در طراحی‌های گرافیکی استفاده
                        می‌شود
                      </p>
                    </div>
                    <div className="notification-item">
                      <span className="date">1400/05/05</span>
                      <p>
                        لورم ایپسوم، نام متنی است که به‌‌عنوان نگهدارنده و
                        نشان‌دهنده‌ی مکان متن در طراحی‌های گرافیکی استفاده
                        می‌شود
                      </p>
                    </div>
                    <div className="notification-item">
                      <span className="date">1400/05/05</span>
                      <p>
                        لورم ایپسوم، نام متنی است که به‌‌عنوان نگهدارنده و
                        نشان‌دهنده‌ی مکان متن در طراحی‌های گرافیکی استفاده
                        می‌شود
                      </p>
                    </div>
                    <div className="notification-item">
                      <span className="date">1400/05/05</span>
                      <p>
                        لورم ایپسوم، نام متنی است که به‌‌عنوان نگهدارنده و
                        نشان‌دهنده‌ی مکان متن در طراحی‌های گرافیکی استفاده
                        می‌شود
                      </p>
                    </div>
                    <div className="notification-item">
                      <span className="date">1400/05/05</span>
                      <p>
                        لورم ایپسوم، نام متنی است که به‌‌عنوان نگهدارنده و
                        نشان‌دهنده‌ی مکان متن در طراحی‌های گرافیکی استفاده
                        می‌شود
                      </p>
                    </div>
                  </div>
                  <div style={{ width: "100%", height: 20 }}></div>
                  <div className="m-t-30 text-center">
                    <Button
                      text="بازگشت"
                      type="button"
                      classes="btnOutLineNormal"
                      width="100%"
                      onClickFn={this.goToBack.bind(this)}
                    />
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
