import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import Button from "../General/Button";
import "../../App.css";

export class Signup extends Component {
  static displayName = Signup.name;

  constructor(props) {
    super(props);

    this.state = {};
  }

  goToSignup(pageId) {
    switch (pageId) {
      case 1:
        this.props.history.push("/signupMerchant");
        break;
      case 2:
        this.props.history.push("/signupCarrier");
        break;
      case 3:
        this.props.history.push("/SignupForwarder");
        break;
      default:
        this.props.history.push("/signup");
    }
  }

  handleBackClick = () => {
    this.props.history.push("/");
  };

  render() {
    const ColoredLine = ({ color, width }) => (
      <hr
        style={{
          color: color,
          backgroundColor: color,
          width: width,
          height: 1,
        }}
      />
    );
    return (
      <div className="outerCntnt">
        <div className="innerCntnt">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              textAlign: "center",
              paddingTop: 25,
              direction: "rtl",
            }}
          >
            <div style={{ float: "right", width: 220, paddingLeft: 15 }}>
              <Card className="cardStyle signup-card">
                <CardBody>
                  <div className="welButn">
                    <img
                      src={require("../../assets/images/signupColorIcon1.jpg")}
                      width="140"
                      alt=""
                    />
                    <br />
                    <span style={{ color: "#151A6A", fontWeight: "bold" }}>
                      بازرگان
                    </span>
                    <ColoredLine width="80%" color="#fefefe" />
                    <Button
                      text="ثبت نام"
                      type="submit"
                      classes="btnCyanNormal"
                      width="120px"
                      onClickFn={() => this.goToSignup(1)}
                    />
                  </div>
                </CardBody>
              </Card>
            </div>
            <div style={{ float: "right", width: 220, paddingLeft: 15 }}>
              <Card className="cardStyle signup-card">
                <CardBody>
                  <div className="welButn">
                    <img
                      src={require("../../assets/images/signupColorIcon2.jpg")}
                      width="140"
                      alt=""
                    />
                    <br />
                    <span style={{ color: "#151A6A", fontWeight: "bold" }}>
                      کریر
                    </span>
                    <ColoredLine width="80%" color="#fefefe" />
                    <Button
                      text="ثبت نام"
                      type="submit"
                      classes="btnOrangeNormal"
                      width="120px"
                      onClickFn={() => this.goToSignup(2)}
                    />
                  </div>
                </CardBody>
              </Card>
            </div>
            <div style={{ float: "right", width: 220, paddingLeft: 15 }}>
              <Card className="cardStyle signup-card">
                <CardBody>
                  <div className="welButn">
                    <img
                      src={require("../../assets/images/signupColorIcon3.jpg")}
                      width="140"
                      alt=""
                    />
                    <br />
                    <span style={{ color: "#151A6A", fontWeight: "bold" }}>
                      فورواردر
                    </span>
                    <ColoredLine width="80%" color="#fefefe" />
                    <Button
                      text="ثبت نام"
                      type="submit"
                      classes="btnBlueNormal"
                      width="120px"
                      onClickFn={() => this.goToSignup(3)}
                    />
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            <Button
              text="بازگشت"
              type="submit"
              classes="btnWhiteNormal"
              width="100px"
              onClickFn={this.handleBackClick}
            />
          </div>
        </div>
      </div>
    );
  }
}
