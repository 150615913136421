import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import { ValidatorForm } from "react-form-validator-core";
import TextValidator from "../General/TextValidator";
import { isMobileNumber } from "../Utils/Utility";
import Button from "../General/Button";
import Axios from "axios";
import API_URL from "../Api/ApiUrl";
import "../../App.css";

export class ForgetStep1 extends Component {
  static displayName = ForgetStep1.name;

  form;
  mobileRef;
  constructor(props) {
    super(props);

    this.state = {
      form: {
        mobile: "",
      },
      errorMessage: "",
      showLoading: false,
    };

    this.form = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    ValidatorForm.addValidationRule("isMobileNumber", (value) =>
      isMobileNumber(value)
    );
  }

  handleChange(event) {
    this.setState({ errorMessage: "" });
    this.setState({
      form: {
        ...this.state.form,
        [event.target.id]: event.target.value,
      },
    });
  }

  async handleSubmit(event) {
    this.setState({ showLoading: true });

    //send sms

    this.goToVerify();
  }

  async goToVerify() {
    this.setState({ showLoading: true });
    const account = "";
    const { history } = this.props;
    history.push({
      pathname: "/codeVerify",
      state: {
        actionType: "forget",
        mobileNumber: this.state.form.mobile,
        objectMdl: account,
      },
    });
  }

  goToLogin() {
    const { history } = this.props;
    history.push("/login");
  }

  render() {
    return (
      <div className="outerCntnt">
        <div className="innerCntnt">
          <div style={{ width: "100%", textAlign: "right", paddingTop: 25 }}>
            <div style={{ float: "right", width: 450, paddingLeft: 15 }}>
              <Card className="cardStyle auth-card">
                <CardBody>
                  <div style={{ textAlign: "center", marginBottom: 20 }}>
                    <img
                      src={require("../../assets/images/padlock.png")}
                      width="60"
                      alt=""
                    />
                    <div style={{ width: 100, height: 10 }}></div>
                    <span style={{ fontSize: 12, fontWeight: "bold" }}>
                      رمز عبور خود را فراموش کرده اید؟
                    </span>
                    <div style={{ width: 100, height: 10 }}></div>
                    <span style={{ fontSize: 14 }}>
                      .برای دریافت کد ورود، شماره موبایل خود را وارد کنید
                    </span>
                  </div>
                  <div className="welButn">
                    <ValidatorForm onSubmit={this.handleSubmit}>
                      <br />
                      <TextValidator
                        type="text"
                        classes="textInputEn"
                        ref={this.mobile}
                        name="mobile"
                        id="mobile"
                        placeholder="09xxxxxxxxx"
                        value={this.state.form.mobile}
                        handleChange={this.handleChange}
                        validators={[
                          "required",
                          "isMobileNumber",
                          "maxStringLength:11",
                        ]}
                        errorMessages={[
                          "شماره موبایل را وارد کنید",
                          " شماره موبایل نا معتبر می باشد",
                          "شماره همراه حداکثر باید 11 رقم باشد",
                        ]}
                      />
                      <br />
                      <br />
                      {this.state.errorMessage ? (
                        <div
                          style={{
                            color: "#cc0000",
                            padding: 15,
                            textAlign: "center",
                          }}
                        >
                          {this.state.errorMessage}
                        </div>
                      ) : null}
                      <div className="m-t-30 text-center">
                        <Button
                          text="ارسال"
                          type="submit"
                          classes="btnBlueNormal"
                          loading={this.state.showLoading}
                        />
                      </div>
                      <div style={{ width: 100, height: 10 }}></div>
                      <div className="m-t-30 text-center">
                        <Button
                          text="بازگشت"
                          type="button"
                          classes="btnOutLineNormal"
                          onClickFn={this.goToLogin.bind(this)}
                        />
                      </div>
                    </ValidatorForm>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
