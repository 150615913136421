import React, { Component } from "react";
import Axios from "axios";
import API_URL from "../Api/ApiUrl";
import { Card, CardBody, Spinner } from "reactstrap";
import {
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CButton,
} from "@coreui/react";
import moment from "jalali-moment";
import Button from "../General/Button";
import Icon from "../Icons/Icon";
import "../../App.css";

export class AddService extends Component {
  static displayName = AddService.name;

  form;

  constructor(props) {
    super(props);

    this.state = {
      Filename: "",
      selectedFile: null,
      Title: "تست",
      isUploaded: false,
      lblMsge: "",
      lblErrMsge: "",
      filePath: "Select Excel file...",
      modalVisible: false,
      btnStatus: "disabled",
      serviceId: 0,
      showLoading: false,
    };

    this.form = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.onSubmitService = this.onSubmitService.bind(this);
  }

  closeConfirmModal() {
    this.setState({ modalVisible: false });
  }

  handleChange(event) {
    this.setState({
      form: {
        ...this.state.form,
        [event.target.id]: event.target.value,
      },
    });
  }

  onSubmitService(eventData) {
    
    eventData.preventDefault();
    console.log(localStorage.getItem("userId"))
    const service = {
      Id: 0,
      Title: this.state.Title,
      ProviderId: Number(localStorage.getItem("userId")),
      RegDate: moment().locale("fa").format("YYYY/MM/DD"),
      ExpireDate: moment().locale("fa").format("YYYY/MM/DD"),
      Views: 0,
      Status: 0,
    };

    Axios.post(API_URL + "Service", service)
      .then((res) => {
        var msg = res.data.message;
        if (msg === "ok") {
          this.setState({ serviceId: res.data.id });
          this.doUploadExcel();
        } else {
          this.setState({ serviceId: res.data.id });
          this.setState({ modalVisible: true });
        }
      })
      .catch((err) => {
        this.setState({ showSuccess: false, showDanger: true });
      });
  }

  doUploadExcel() {
    
    this.setState({ showLoading: true });
    this.setState({ modalVisible: false });
    const formData = new FormData();
    formData.append(
      "file",
      this.state.selectedFile
      //  this.state.selectedFile.name
    );

    Axios({
      url:
        API_URL +
        "file/upload/" +
        localStorage.getItem("userId") +
        "/" +
        this.state.serviceId,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then((response) => {
        this.setState({ showLoading: false });
        this.setState({ lblMsge: "اطلاعات با موفقیت بارگذاری شد" });
        this.setState({ lblErrMsge: "" });
        this.setState({ btnStatus: "disabled" });
        var _parts = this.state.Filename.split(".");
        var fileName = _parts[0];
        var fileExtn = _parts[1];
        fileName =
          fileName.replace(/\s/g, "_") +
          "_" +
          this.props.project_id +
          "." +
          fileExtn;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onFileChange = (event) => {
    if (!event.target.files[0].name.match(/.(xls|xlsx)$/i)) {
      this.setState({ lblMsge: "" });
      this.setState({
        lblErrMsge: "نوع فایل مجاز نیست. (فرمت های قابل قبول: XLS, XLSX)",
      });
      this.setState({ isUploaded: true });
      this.setState({ btnStatus: "disabled" });
    } else {
      this.setState({ lblErrMsge: "" });
      this.setState({ isUploaded: false });
      this.setState({ btnStatus: "" });
      if (event.target.files && event.target.files[0]) {
        this.setState({ filePath: event.target.files[0].name });
        this.setState({ selectedFile: event.target.files[0] });
        this.setState({ Filename: event.target.files[0].name });
      }
    }
  };

  goToPanel() {
    var role_id = localStorage.getItem("roleId");
    switch (role_id) {
      case "2":
        this.props.history.push("/merchant");
        break;
      case "3":
        this.props.history.push("/carrier");
        break;
      case "4":
        this.props.history.push("/forwarder");
        break;
      case "5":
      case "6":
        this.props.history.push("/merchant");
        break;
    }
  }

  render() {
    const styles = {
      wrapDrpdwns: {
        display: "flex",
        justifyContent: "flex-start",
        height: 60,
        textAlign: "center",
      },
      boxDrpdown: {
        width: "20%",
        backgroundColor: "gray",
        display: "inline-block",
        border: "1px solid #ededed",
      },
      inputText: {
        height: 60,
      },
      drpdown: {
        padding: "5px",
        height: 60,
        width: "100%",
        border: "0px",
        fontSize: "16px",
      },
    };
    return (
      <div className="outerCntnt">
        <div
          className="centerContent add-service"
          style={{ width: "70%", textAlign: "right", paddingTop: 10 }}
        >
          <div
            style={{
              float: "right",
              width: "100%",
              paddingLeft: 15,
              transform: "scale(0.9)",
            }}
          >
            <div style={{ marginBottom: 20, marginRight: 20 }}>
              <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                تعریف سرویس جدید
              </span>
            </div>

            <Card
              className="cardStyle"
              style={{ height: "auto", padding: "3rem 2rem" }}
            >
              <CardBody>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "0px solid #007700",
                    }}
                  >
                    <img
                      src={require("../../assets/images/percentgray.png")}
                      width="150"
                      alt=""
                      style={{ marginBottom: "3rem" }}
                    />
                    <Button
                      text="تأیید"
                      type="submit"
                      classes="btnOrangeBox"
                      width="150px"
                      disabled={this.state.btnStatus}
                      onClickFn={this.onSubmitService}
                    />
                    <Button
                      text="بازگشت"
                      type="submit"
                      classes="btnOutlineBlueBox"
                      width="150px"
                      onClickFn={this.goToPanel.bind(this)}
                    />
                  </div>
                  <div style={{ width: "5%" }}></div>
                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      flexDirection: "column",
                      border: "0px solid #007700",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        marginBottom: 15,
                      }}
                    >
                      بارگذاری فایل اکسل
                    </span>
                    <label htmlFor="Filename" className="custom-file-upload">
                      <Icon
                        icon={"attachment"}
                        size={18}
                        color="gray"
                        style={{ marginRight: 20 }}
                      />
                      {this.state.filePath}
                    </label>
                    <input
                      type="file"
                      name="Filename"
                      id="Filename"
                      onChange={(e) => this.onFileChange(e)}
                      style={{ display: "none" }}
                    />
                    <div style={{ textAlign: "left", padding: 5 }}>
                      <a
                        href="/help/فایل مرجع.xlsm"
                        download="فایل مرجع.xlsm"
                        style={{
                          display: "inline-block",
                          color: "#2B61CD",
                          fontSize: "13px",
                          marginBottom: 5,
                          cursor: "pointer",
                        }}
                      >
                        دانلود فایل مرجع
                      </a>
                      <a
                        href="/help/فایل بارگذاری اطلاعات.xlsx"
                        download="فایل بارگذاری اطلاعات.xlsx"
                        style={{
                          display: "inline-block",
                          color: "#2B61CD",
                          fontSize: "13px",
                          marginBottom: 5,
                          cursor: "pointer",
                          marginLeft: 20,
                        }}
                      >
                        دانلود فایل بارگذاری اطلاعات
                      </a>
                    </div>
                    {this.state.lblErrMsge ||
                    this.state.lblMsge ||
                    this.state.showLoading ? (
                      <div
                        style={{
                          textAlign: "center",
                          padding: 5,
                          direction: "rtl",
                          border: "0px solid #007700",
                        }}
                      >
                        {this.state.lblErrMsge ? (
                          <span
                            style={{
                              color: "#bb0000",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            {this.state.lblErrMsge}
                          </span>
                        ) : null}
                        {this.state.lblMsge ? (
                          <span
                            style={{
                              color: "#00aa00",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            {this.state.lblMsge}
                          </span>
                        ) : null}
                        {this.state.showLoading ? (
                          <div>
                          <Spinner color="gray" />
                          <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        marginBottom: 15,
                      }}
                    >
                      در حال آپلود فایل...
                    </span>
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        marginBottom: 15,
                      }}
                    >
                      راهنمای بارگذاری فایل
                    </span>
                    <div className="helpText">
                      اﺑﺘﺪا ﻓﺎﯾﻞ مرجع را داﻧﻠﻮد و پس از تکمیل در فایل بارگذاری
                      اطلاعات paste value نمایید. در ﻧﻈﺮ داﺷﺘﻪ ﺑﺎﺷﯿﺪ ﺑﺎ آﭘﻠﻮد
                      ﻓﺎﯾﻞ ﺟﺪﯾﺪ ﮐﻠﯿﻪ سروﯾﺲ ﻫﺎی ﻗﺒﻠﯽ ﺣﺬف و سروﯾﺲ ﻫﺎی ﻓﺎﯾﻞ ﺟﺪﯾﺪ
                      ﺑﺎرﮔﺬاری ﻣﯽ ﮔﺮدﻧﺪ.
                      <br />
                      نکته:
                      <ul style={{ paddingRight: "1.5rem" }}>
                        <li>
                          فرمت نام بنادر باید حتماً مطابق دیتابیس سیستم باشد و
                          بعد از یافتن آن در باکس جستجو، عین عبارت را در ستون
                          مورد نظر کپی پیست نمایید.
                        </li>
                        <li>
                          نوع بار و نوع کانتینر را حتماً از گزینه های موجود
                          انتخاب بفرمایید.
                        </li>
                        <li>
                          موجودی کانتینر را برای مقصد مورد نظر بدون در نظر گرفتن
                          کانتینرهای معیوب یا در حال تعمیر وارد نمایید.
                        </li>
                        <li>
                          کرایه حمل را تنها به عدد وارد نمایید (بدون پسوند و
                          پیشوند).
                        </li>
                        <li>ترم حمل CY-CY در نظر گرفته شده است.</li>
                        <li>
                          در صورتیکه تمایل به قیمت گذاری در مسیر خاصی ندارید و
                          تنها مایلید سرویس شما در سرچ نمایش داده شود، لطفاً
                          ستون کرایه حمل را خالی بگذارید.
                        </li>
                        <li>
                          تاریخ اعتبار قیمت را مطابق نمونه تیتر وارد نمایید.
                        </li>
                        <li>
                          دو خط اول صرفاً جهت نمونه پرشده و خواهشمند است قبل از
                          بارگذاری فایل نهایی خود در سایت این دو سطر را پاک
                          نمایید.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>

        <CModal
          show={this.state.modalVisible}
          closeOnBackdrop={false}
          className="divRtl"
        >
          <CModalHeader closeButton></CModalHeader>
          <CModalBody>
            <span>
              سرویس های قبلی شما حذف خواهد شد، برای بارگذاری این اطلاعات مطمئن
              هستید؟
            </span>
          </CModalBody>
          <CModalFooter>
            <CButton color="primary" onClick={this.doUploadExcel.bind(this)}>
              بله
            </CButton>{" "}
            <CButton
              color="secondary"
              onClick={this.closeConfirmModal.bind(this)}
            >
              خیر
            </CButton>
          </CModalFooter>
        </CModal>
      </div>
    );
  }
}
