import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { PrivateRoute, PublicRoute } from "./components/Routes/PrivateRoute";
import { Spinner } from "reactstrap";
import { AuthLayout } from "./components/Layout/AuthLayout";
import { UserLayout } from "./components/Layout/UserLayout";
import { Welcome } from "./components/Start/Welcome";
import { Account } from "./components/Account/Account";
import Login from "./components/Auth/Login";
import { Signup } from "./components/Signup/Signup";
import { SignupMerchant } from "./components/Signup/SignupMerchant";
import { SignupForwarder } from "./components/Signup/SignupForwarder";
import { SignupCarrier } from "./components/Signup/SignupCarrier";
import { ConfirmPersonal } from "./components/Signup/ConfirmPersonal";
import { ConfirmCompany } from "./components/Signup/ConfirmCompany";
import { AddSeller } from "./components/Seller/AddSeller";
import { Carrier } from "./components/Panel/Carrier";
import { Forwarder } from "./components/Panel/Forwarder";
import { Merchant } from "./components/Panel/Merchant";
import { AddService } from "./components/Services/AddService";
import { AddOffer } from "./components/Offers/AddOffer";
import { Profile } from "./components/User/Profile";
import { Messages } from "./components/Messages/Messages";
import { Logout } from "./components/Pages/Logout";
import { NotFound } from "./components/Pages/NotFound";
import { ForgetStep1 } from "./components/Auth/ForgetStep1";
import { ForgetStep2 } from "./components/Auth/ForgetStep2";
import { CodeVerify } from "./components/Auth/CodeVerify";
import { About } from "./components/Pages/About";
import { Contact } from "./components/Pages/Contact";
import { WhyVolga } from "./components/Pages/WhyVolga";
import { VerifyPayment } from "./components/Pages/VerifyPayment";
import "./App.css";

export default class App extends Component {
  static displayName = App.name;

  render() {
    const loading = () => (
      <div className="animated fadeIn pt-3 text-center">
        <Spinner style={{ width: "3rem", height: "3rem" }} color="danger" />
      </div>
    );

    return (
      <BrowserRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route
              exact
              path={[
                "/",
                "/account",
                "/login",
                "/signup",
                "/signupMerchant",
                "/signupForwarder",
                "/signupCarrier",
                "/confirmPersonal",
                "/confirmCompany",
                "/addSeller",
                "/forgetStep1",
                "/forgetStep2",
                "/codeVerify",
                "/about",
                "/contact",
                "/why-volga",
                "/verifypayment",
              ]}
            >
              <Switch>
                <PublicRoute exact path="/" component={Welcome} />
                <AuthLayout>
                  <PublicRoute path="/login" component={Login} />
                  <PublicRoute path="/account" component={Account} />
                  <PublicRoute path="/signup" component={Signup} />
                  <PublicRoute
                    path="/signupMerchant"
                    component={SignupMerchant}
                  />
                  <PublicRoute
                    path="/signupForwarder"
                    component={SignupForwarder}
                  />
                  <PublicRoute
                    path="/signupCarrier"
                    component={SignupCarrier}
                  />
                  <PublicRoute
                    path="/confirmPersonal"
                    component={ConfirmPersonal}
                  />
                  <PublicRoute
                    path="/confirmCompany"
                    component={ConfirmCompany}
                  />
                  <PublicRoute path="/addSeller" component={AddSeller} />
                  <PublicRoute path="/forgetStep1" component={ForgetStep1} />
                  <PublicRoute path="/forgetStep2" component={ForgetStep2} />
                  <PublicRoute path="/codeVerify" component={CodeVerify} />

                  {/* inner pages */}
                  <Route path="/about" component={About} />
                  <Route path="/contact" component={Contact} />
                  <Route path="/why-volga" component={WhyVolga} />
                  <Route path="/verifypayment" component={VerifyPayment} />
                </AuthLayout>
              </Switch>
            </Route>

            <Route
              exact
              path={[
                "/carrier",
                "/forwarder",
                "/merchant",
                "/addService",
                "/AddOffer",
                "/profile",
                "/messages",
                "/logout",
              ]}
            >
              <UserLayout>
                <Switch>
                  <PrivateRoute exact path="/carrier" component={Carrier} />
                  <PrivateRoute path="/forwarder" component={Forwarder} />
                  <PrivateRoute path="/merchant" component={Merchant} />
                  <PrivateRoute path="/addService" component={AddService} />
                  <PrivateRoute path="/addOffer" component={AddOffer} />
                  <PrivateRoute path="/profile" component={Profile} />
                  <PrivateRoute path="/messages" component={Messages} />
                  <PrivateRoute path="/logout" component={Logout} />
                </Switch>
              </UserLayout>
            </Route>

            <Route path="*">
              <Switch>
                <Route component={NotFound} />
              </Switch>
            </Route>
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}
