export const formatMoney = (
  amount,
  decimalCount = 0,
  decimal = ".",
  thousands = ","
) => {
  decimalCount = Math.abs(decimalCount);
  decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
  const negativeSign = amount < 0 ? "-" : "";
  let i = parseInt(
    (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
  ).toString();
  let j = i.length > 3 ? i.length % 3 : 0;
  return (
    negativeSign +
    (j ? i.substr(0, j) + thousands : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
    (decimalCount
      ? decimal +
        Math.abs(amount - i)
          .toFixed(decimalCount)
          .slice(2)
      : "")
  );
};

export const isValidIranianNationalCode = (input) => {
  if (
    !/^\d{10}$/.test(input) ||
    input == "0000000000" ||
    input == "1111111111" ||
    input == "2222222222" ||
    input == "3333333333" ||
    input == "4444444444" ||
    input == "5555555555" ||
    input == "6666666666" ||
    input == "7777777777" ||
    input == "8888888888" ||
    input == "9999999999"
  )
    return false;
  var check = parseInt(input[9]);
  var sum = 0;
  var i;
  for (i = 0; i < 9; ++i) {
    sum += parseInt(input[i]) * (10 - i);
  }
  sum %= 11;
  let result = (sum < 2 && check == sum) || (sum >= 2 && check + sum == 11);
  return result;
};

export const isMobileNumber = (input) => {
  if (!/(\+98|0)?9\d{9}/.test(input)) {
    return false;
  }
  return true;
};

export const isEmailAddress = (input) => {
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(input)) {
    return false;
  }
  return true;
};

export const showAllIcons = () => {
  const icons = document.querySelectorAll(".base-fa-icon");
  if (icons && icons.length > 0) {
    icons.forEach((icon) => (icon.style.display = "block"));
  }
};

export const hideAllIcons = () => {
  const icons = document.querySelectorAll(".base-fa-icon");
  if (icons && icons.length > 0) {
    icons.forEach((icon) => (icon.style.display = "none"));
  }
};

export const showByClassName = (className) => {
  const item = document.querySelector(`.${className}`);
  if (item) {
    item.style.display = "block";
  }
};

export const hideByClassName = (className) => {
  const item = document.querySelector(`.${className}`);
  if (item) {
    item.style.display = "none";
  }
};

export const onClickItem = (history, page, iconClassName) => {
  const item = document.querySelector(`.${iconClassName}`);
  if (item) {
    item.addEventListener("click", (e) => {
      e.preventDefault();
      goToPage(history, page);
    });
  }
};

export const goToPage = (history, page) => history.push(page);

export const signOut = (history) => {
  const element = document.querySelector(".sign-out-icon");
  if (element) {
    element.addEventListener("click", (e) => {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("installmenDetail");
      localStorage.removeItem("insuranceDetails");
      history.push("/register");
    });
  }
};

export const registerServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      navigator.serviceWorker
        .register("service-worker.js")
        .then(
          (registration) => {
            console.log(
              "ServiceWorker registration successful with scope: ",
              registration.scope,
              registration
            );
          },
          (err) => {
            console.log("ServiceWorker registration failed: ", err);
          }
        )
        .catch((err) => {
          console.log(err);
        });
    });
  } else {
    console.log("service worker is not supported");
  }
};

export const onClickForHiddenAnotherItem = (
  clickedItemSelector,
  targetItemSelector
) => {
  const clickedItem = document.querySelector(clickedItemSelector);
  clickedItem.addEventListener("click", (event) => {
    const targetItem = document.querySelector(targetItemSelector);
    targetItem.style.display = "none";
  });
};

export const getWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  //  const now = new Date()
  // compare the expiry time of the item with the current time
  //  if (now.getTime() > item.expiry) {
  // If the item is expired, delete the item from storage
  // and return null
  //  localStorage.removeItem(key)
  //  return null
  //  }
  return item.value;
};
