import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { ValidatorForm } from "react-form-validator-core";
import TextValidator from "../General/TextValidator";
import { isEmailAddress } from "../Utils/Utility";
import Button from "../General/Button";
import Icon from "../Icons/Icon";
import Axios from "axios";
import API_URL from "../Api/ApiUrl";
import "../../App.css";

// export class Login extends Component {
//     static displayName = Login.name;

class Login extends Component {
  form;
  usernameRef;
  constructor(props) {
    super(props);

    this.state = {
      form: {
        username: "",
        password: "",
      },
      showPassword: false,
      errorMessage: "",
      showLoading: false,
    };

    this.form = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleShowPassword = this.toggleShowPassword.bind(this);
  }

  componentDidMount() {
    ValidatorForm.addValidationRule("isEmailAddress", (value) =>
      isEmailAddress(value)
    );
  }

  handleChange(event) {
    this.setState({ errorMessage: "" });
    this.setState({
      form: {
        ...this.state.form,
        [event.target.id]: event.target.value,
      },
    });
  }

  async handleSubmit() {
    this.setState({ showLoading: true });

    // check the payment status
    const payId = localStorage.getItem("payId");
    if (payId) {
      const paymentStatus = await this.checkPaymentStatus();

      // ************* */ THIS OPTION IS MUTED IF U WANT TO USE IT PLZ UNCOMMENT IT ! ***************

      // if (!paymentStatus) {
      //   // payment was not completed before
      //   this.onPaymentCall();
      //   return;
      // }
    }

    fetch(API_URL + "user/auth", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        Username: this.state.form.username,
        Password: this.state.form.password,
      }),
    })
      .then((Response) => Response.json())
      .then(async (result) => {
        if (result.id === 0) {
          this.setState({ showLoading: false });
          this.setState({ errorMessage: "ایمیل یا کلمه عبور نادرست است" });
        } else {
          if (result.roleId == 3 || result.roleId == 4) {
            //check signup status
            Axios.get(API_URL + "Provider/GetSignupStatus/" + result.id)
              .then(async (response) => {
                var regType = response.data.reg_type;
                var regStatus = response.data.reg_status;
                if (regStatus === 1) {
                  // check if user completed the addSeller
                  const isNeedTo = await this.userNeedToAddSellers(result.id);
                  if (isNeedTo) {
                    this.props.history.push({
                      pathname: "/addSeller",
                      recordId: result.id,
                    });
                  } else {
                    // else, let the user login
                    console.log("hello im just get data")
                    localStorage.setItem("access", result.access);
                    localStorage.setItem("userId", result.id);
                    localStorage.setItem("userName", result.username);
                    localStorage.setItem("nameFamily", result.nameFamily);
                    localStorage.setItem("roleId", result.roleId);
                    localStorage.setItem("roleTitle", result.roleTitle);
                    sessionStorage.setItem("isLoggedIn", true);
                    this.setState({ showLoading: false });

                    var roleType = "";
                    switch (result.roleId) {
                      case 3:
                        roleType = "C";
                        localStorage.setItem("userCode", roleType + result.id);

                        this.props.history.push("/carrier");
                        break;
                      case 4:
                        roleType = "F";
                        localStorage.setItem("userCode", roleType + result.id);

                        this.props.history.push("/forwarder");
                        break;
                    }
                  }
                } else {
                  // redirect user to continue signup
                  const utype = localStorage.getItem("utype");
                  const signupType =
                    utype === "merchant" ? 1 : utype === "provider" ? 2 : null;
                  const pathname =
                    regType === 1 ? "/confirmPersonal" : "/confirmCompany";

                  this.props.history.push({
                    pathname,
                    signupType,
                    recordId: result.id,
                    action: "add",
                  });
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            localStorage.setItem("access", result.access);
            localStorage.setItem("userId", result.id);
            localStorage.setItem("userName", result.username);
            localStorage.setItem("nameFamily", result.nameFamily);
            localStorage.setItem("roleId", result.roleId);
            localStorage.setItem("roleTitle", result.roleTitle);
            sessionStorage.setItem("isLoggedIn", true);
            this.setState({ showLoading: false });

            var roleType = "";
            switch (result.roleId) {
              case 2:
                roleType = "M";
                localStorage.setItem("userCode", roleType + result.id);

                this.props.history.push("/merchant");
                break;
              case 5:
                roleType = "S";
                localStorage.setItem("userCode", roleType + result.id);

                this.props.history.push("/merchant");
                break;
              case 6:
                roleType = "A";
                localStorage.setItem("userCode", roleType + result.id);

                this.props.history.push("/merchant");
                break;
              default:
                roleType = "U";
                localStorage.setItem("userCode", roleType + result.id);

                this.props.history.push("/merchant");
                break;
            }
          }
        }
      });
  }

  async checkPaymentStatus() {
    const payId = localStorage.getItem("payId");

    try {
      const res = await Axios.post(API_URL + `payment/${payId}`, {
        id: payId,
      });
      const payStatus = Number(res.data.payStatus);
      return payStatus === 100 || payStatus === 101;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  onPaymentCall() {
    const userId = localStorage.getItem("userId");
    if (userId) {
      Axios.post(API_URL + `repayment/${userId}`, {
        id: userId,
      })
        .then((res) => {
          const authority = res.data.authority;
          const payId = res.data.payId;

          localStorage.setItem("payId", payId);

          window.location = `https://www.zarinpal.com/pg/StartPay/${authority}`;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  async userNeedToAddSellers(userID) {
    try {
      const response = await Axios.get(
        API_URL + "Seller/SellerCountByRefId/" + userID
      );
      const permitCount = response.data.permit_count;
      const sellerCount = response.data.seller_count;
      return !!permitCount && permitCount !== sellerCount;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  toggleShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  goToSignup() {
    const { history } = this.props;
    history.push("/signup");
  }

  goToForget() {
    const { history } = this.props;
    history.push("/forgetStep1");
  }

  goToStart() {
    const { history } = this.props;
    history.push("/");
  }

  render() {
    return (
      <div className="outerCntnt">
        <div className="innerCntnt">
          <div style={{ width: "100%", textAlign: "right", paddingTop: 25 }}>
            <div style={{ float: "right", width: 460, paddingLeft: 15 }}>
              <Card className="cardStyle auth-card">
                <CardBody>
                  <div className="label-with-icon">
                    <span>لطفاً وارد حساب کاربری خود شوید</span>
                  </div>
                  <div className="welButn">
                    <ValidatorForm onSubmit={this.handleSubmit}>
                      <div className="label-with-icon">
                        <span>
                          ایمیل
                          <Icon icon="user" size={15} />
                        </span>
                      </div>
                      <TextValidator
                        type="text"
                        classes="textInput"
                        ref={this.usernameRef}
                        name="username"
                        id="username"
                        placeholder="sample@gmail.com"
                        value={this.state.form.username}
                        handleChange={this.handleChange}
                        validators={["required", "isEmailAddress"]}
                        errorMessages={[
                          "ایمیل را وارد کنید",
                          " آدرس ایمیل نا معتبر می باشد",
                        ]}
                      />
                      <br />
                      <div className="label-with-icon">
                        <span>
                          رمز عبور
                          <Icon icon="lock" size={15} />
                        </span>
                      </div>
                      <div className="password-field-wrapper">
                        <TextValidator
                          type={this.state.showPassword ? "text" : "password"}
                          classes="textInput"
                          ref={this.passwordRef}
                          name="password"
                          id="password"
                          placeholder="*****"
                          value={this.state.form.password}
                          handleChange={this.handleChange}
                          validators={["required"]}
                          errorMessages={["کلمه عبور را وارد کنید"]}
                        />
                        <span
                          className="eye-icon-button"
                          onClick={this.toggleShowPassword}
                          style={{ cursor: "pointer" }}
                        >
                          {this.state.showPassword ? (
                            <Icon icon="view-hide" size={15} />
                          ) : (
                            <Icon icon="view-show" size={15} />
                          )}
                        </span>
                      </div>
                      <div
                        style={{
                          color: "#cc0000",
                          padding: 15,
                          textAlign: "center",
                        }}
                      >
                        {this.state.errorMessage}
                      </div>
                      <div className="m-t-30 text-center">
                        <Button
                          text="ورود"
                          type="submit"
                          classes="btnBlueNormal"
                          loading={this.state.showLoading}
                        />
                      </div>
                      <br />
                      <div onClick={this.goToForget.bind(this)}>
                        <span
                          style={{
                            verticalAlign: "middle",
                            color: "#0000dd",
                            fontSize: "12px",
                            marginBottom: 5,
                            cursor: "pointer",
                          }}
                        >
                          رمز عبور خود را فراموش کرده اید؟
                        </span>
                      </div>
                      <br />
                      <div className="m-t-30 text-center">
                        <Button
                          text="ثبت نام"
                          type="button"
                          classes="btnOutLineNormal"
                          onClickFn={this.goToSignup.bind(this)}
                        />
                      </div>
                      <div style={{ width: 100, height: 10 }}></div>
                      <div className="m-t-30 text-center">
                        <Button
                          text="بازگشت"
                          type="submit"
                          classes="btnOutLineNormal"
                          onClickFn={this.goToStart.bind(this)}
                        />
                      </div>
                    </ValidatorForm>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Login);
