import React from "react";
import { ValidatorComponent } from "react-form-validator-core";
import { maxNumber } from "react-form-validator-core/lib/ValidationRules";
import TextInput from "./TextInput";
class TextValidator extends ValidatorComponent {
  constructor(props) {
    super(props);
    this.errorText = this.errorText.bind(this);
  }
  renderValidatorComponent() {
    return (
      <div className="width-100 position-relative m-b-30">
        <TextInput
          type={this.props.type}
          classes={
            this.props.classes + (this.errorText() ? " input-has-error" : "")
          }
          paddingRight={this.props.paddingRight}
          name={this.props.name}
          value={this.props.value}
          handleChange={this.props.handleChange}
          validators={this.props.validators}
          errorMessages={this.props.errorMessages}
          placeholder={this.props.placeholder}
          maxNumber={this.props.maxNumber}
          length={this.props.length}
        />
        <span
          className={this.props.name + "-requared-error error"}
          id={this.props.name + "RequaredError"}
        >
          {" "}
          {this.errorText()}
        </span>
        <span className="base-input-lable">
          {this.props.lable ? this.props.lable + " :" : ""}
        </span>
      </div>
    );
  }
  errorText() {
    const { isValid } = this.state;
    if (isValid) {
      return null;
    }
    return this.getErrorMessage();
  }
}
export default TextValidator;
