import React from "react";
import Modal from "react-modal";
import Button from "../General/Button";

Modal.setAppElement("*");
const customStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(110, 110, 110, 0.30)",
    zIndex: 999,
  },
  content: {
    width: "1200px",
    maxWidth: "1200px",
    height: window.innerHeight < 700 ? 480 : 600,
    inset: "unset",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
    borderRadius: 25,
    overflow: "hidden",
  },
};

const TermsModal = ({ visible, onConfirm, onClose }) => {
  return (
    <Modal isOpen={visible} onRequestClose={onClose} style={customStyles}>
      <div className="offer-modal-body">
        <h3 className="title">
          قوانین استفاده از خدمات آنلاین وب-اپلیکیشن ولگا
        </h3>

        <div className="description-area">
          <div className="description-content terms-modal-content">
            <div className="row">
              <div className="col-sm-6">
                <p>
                  با ثبت‌نام و استفاده از سرویس‌های جستجو و ارایه سرویس از سایت
                  ولگا شما شرایط زیر را می‌پذیرید و با رعایت آنها حین استفاده از
                  سرویس‌ها موافقت کامل خود را اعلام می‌کنید. توصیه می کنیم یک
                  نسخه از این شرایط را برای مراجعات بعدی چاپ یا ذخیره کنید.
                </p>
                <ul>
                  <li>
                    با ایجاد یک حساب کریر، فورواردر یا بازرگان یا با برقراری
                    ارتباط با اعضای تیم ما در مورد ارائه خدمات خود، یا دریافت
                    خدمات ، شما موافقت می کنید که با درخواست های حمل و نقل و
                    درخواست های بوکینگ یا ایجاد ارتباطات جدید به شما مراجعه کنیم
                    و همچنین شما را در فهرست راهنماهای ارائه دهندگان سرویسها یا
                    مراجعه کننده جهت دریافت سرویس قرار دهیم.
                  </li>
                  <li>
                    در شبکه‌های قوی ایجاد شده توسط این سایت ،خط مشی رازداری ، ما
                    را ملزم به انتشار اطلاعات دقیقا مطابق با فایل ارایه شده توسط
                    حساب کاربری شما می‌نماید و با استفاده از سایت، شما با چنین
                    پردازشی موافقت می‌کنید و تضمین می‌کنید که تمام داده‌های
                    ارائه شده توسط شما دقیق است و در صورت تماس بازرگانان
                    راهنمایی‌های لازم درخصوص قیمت یا سرویس با جزییات مطابق شرایط
                    حمل شما و قوانین جاری سازمان بنادر و دریانوردی جمهوری اسلامی
                    ایران، ارایه خواهد شد
                  </li>
                  <li>
                    ما هر از چند گاهی شرایط عضویت را اصلاح می‌کنیم و شما موظف به
                    مرور هر ماهه‌ی قوانین جدید هستید و تایید اولیه‌ی شما به
                    منزله‌ی تایید اصلاحات جدید در قوانین عضویت نیز به حساب خواهد
                    آمد.
                  </li>
                  <li>
                    خواهشمند است هر بار که می‌خواهید از سایت استفاده کنید، این
                    شرایط را مجدد بررسی کنید تا مطمئن شوید که شرایطی را که در آن
                    زمان اعمال می‌شود، درک می‌کنید و قبول دارید.
                  </li>
                </ul>
              </div>
              <div className="col-sm-6">
                <ul>
                  <li className="feature-item">
                    لازم به ذکر است در بازه ی زمانی کنونی این سایت تنها به ارایه
                    سرویس یا پیدا کردن سرویس حمل دریایی مورد نظر کمک نموده و هیچ
                    گونه مسولیتی در قبال شرایط بوکینگ ، موجودی کانتینر ، وضعیت
                    حمل ، تعهدات فروشنده یا خریدار و هر گونه ضرر یا زیان مرتبط
                    با بوکینگ‌هایی که از طریق این درگاه معرفی شده‌اند نخواهد
                    داشت.{" "}
                  </li>
                  <li>
                    ممکن است هر از چند گاهی سایت را بروز کرده و تغییر دهیم تا
                    تغییرات در فیچرها ، فراخور نیازهای کاربران و اولویت های
                    بازار توانمندی ما و به بهینه‌ترین حالت ممکن سرویس‌های شما را
                    منعکس کند.
                  </li>
                  <li>
                    ممکن است سایت را به حالت تعلیق درآوریم یا از دسترس برای مدت
                    کوتاهی خارج کنیم.
                  </li>
                  <li>
                    با کمال احترام تیم ولگا تضمین نمی‌کند که سایت، یا هر محتوای
                    موجود در آن، همیشه در دسترس یا بدون وقفه باشد و ممکن است
                    مشکلات نرم افزاری در هر برهه‌ی زمانی رخ دهد.{" "}
                  </li>
                  <li>
                    ما ممکن است به دلایل تجاری و عملیاتی در دسترس بودن تمام یا
                    بخشی از سایت را به حالت تعلیق درآوریم یا لغو یا محدود کنیم.
                    تیم ولگا تمام تلاش خود را خواهد کرد تا در مورد هرگونه تعلیق
                    یا تغییر به موقع به شما اطلاع رسانی کند، اما به دلیل حجم
                    زیاد مخاطب و شرایط مداوم بروزرسانی مسئولیتی در قبال عدم
                    انجام این کار نخواهد داشت.
                  </li>
                  <li>
                    شما همچنین مسئول اطمینان از این هستید که همه افرادی که از
                    طریق اتصال اینترنتی شما (اکانت شما) به سایت دسترسی دارند از
                    این شرایط استفاده و سایر شرایط و ضوابط قابل اجرا آگاه باشند
                    و آنها را با دقت رعایت کنند.
                  </li>
                </ul>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Button
                    classes="btnBlueNormal"
                    text="موافقم"
                    type="button"
                    width="100px"
                    onClickFn={onConfirm}
                  />
                  <div style={{ height: 10 }} />
                  <Button
                    classes="btnOutLineNormal"
                    text="بازگشت"
                    type="button"
                    width="100px"
                    onClickFn={onClose}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TermsModal;
