import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import Button from "../General/Button";
import "../../App.css";

export class WhyVolga extends Component {
  static displayName = WhyVolga.name;

  constructor(props) {
    super(props);
  }

  goToHome() {
    const { history } = this.props;
    history.push("/");
  }

  render() {
    return (
      <div className="outerCntnt">
        <div
          className="centerContent"
          style={{ width: 1000, textAlign: "right", paddingTop: 10 }}
        >
          <div
            style={{ float: "right", width: "100%", transform: "scale(0.92)" }}
          >
            <Card className="cardStyle inner-pages-card">
              <CardBody>
                <h1>چرا ولگا؟ </h1>
                <div className="feature-row">
                  <div className="feature-col">
                    <div className="feature-item">
                      شناخت و پیدا کردن سرویس های نادر بازار تنها یک کلیک با شما
                      فاصله دارد و قرار نیست روزها منتظر جستجوی تیم بازرگانی
                      خود، زمان و هزینه صرف کنید
                    </div>
                    <div className="feature-item">
                      ولگا می‌تواند راه را برای گسترش تجارت شما تنها با چند کلیک
                      ساده و آسان مهیا کند و اعتبار بیزینس شما را با دریافت
                      فیدبک‌های مثبت وبالا بردن رنکینگ خدمات شما ، و دریافت و
                      ارایه بهترین سرویس و مناسب‌ترین قیمت چند برابر نماید
                    </div>
                    <div className="feature-item">
                      نمایش سرویسهای جدید شما در این پلت فرم و طیف وسیع مشتریان
                      حاضر، فرصت‌های جدید بی نظیری را برای دیده شدن شما با
                      کمترین هزینه ممکن ایجاد می‌کند.
                    </div>
                    <div className="feature-item">
                      تیم پشتیبانی ما با آغوش باز در انتظار نظرات و انتقادات
                      سازنده شما در مسیر رشد دسته جمعی این صنعت خواهد بود .
                    </div>
                    <Button
                      text="بازگشت"
                      type="submit"
                      classes="btnOutLineNormal"
                      styles={{ marginTop: "2rem" }}
                      onClickFn={this.goToHome.bind(this)}
                    />
                  </div>
                  <div className="feature-col">
                    <div className="feature-item">
                      ولگا اولین پلت فرم بی تو بی برقراری ارتباط بازار وسیع و
                      پویای صنعت حمل و نقل دریایی می‌باشد. با تجربه ی برقراری
                      ارتباط شما با کریر ، فورواردر یا بازرگان مد نظرتان از طریق
                      این پلت فرم ، در صورتی که طرف معامله (دریافت کننده یا
                      ارایه دهنده سرویس) از خدمات وروند سرویس راضی باشند، مشتری
                      وفادار شما خواهند شد و ارتباط خود را به مدت طولانی مستقیما
                      و بی واسطه با شما حفظ می‌کنند..
                    </div>
                    <div className="feature-item">
                      پیگیری یک استعلام ساده در بازار حال حاضر ایران ممکن است
                      منجر به تماس‌های متعدد بی نتیجه و زمانبربا خطوط بی شمار و
                      فورواردرهای زیادی باشد ، چه برسد که دسترسی و ارتباط از
                      طریق شبکه‌های اجتماعی برای شما به هر دلیلی هم قطع شود. این
                      درگاه می‌تواند به سادگی در کمترین زمان ممکن دایره المعارفی
                      باشد برای برقراری کمترین تماس‌ها به موثر ترین شکل ممکن.
                    </div>
                    <div className="feature-item">
                      عضویت و نمایش خدماتتان در ولگا، فرصت بی نظیری برای شناخته
                      شدن تجارت شما در بین سایر رقبای به روز این صنعت خواهد بود.
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}
