import React, { Component } from "react";
import Axios from "axios";
import API_URL from "../Api/ApiUrl";
import { Card, CardBody, Table, Spinner } from "reactstrap";
import { ValidatorForm } from "react-form-validator-core";
import RCSelect, { components } from "react-select";
import Select from "react-dropdown-select";
import HtmlParser from "react-html-parser";
import Button from "../General/Button";
import Icon from "../Icons/Icon";
import Modal from "react-modal";
import "../../App.css";

Modal.setAppElement("*");
const customStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(110, 110, 110, 0.30)",
    zIndex: 999,
  },
  content: {
    width: "800px",
    maxWidth: "800px",
    minHeight: 380,
    inset: "unset",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
    borderRadius: 25,
    overflowY: "hidden",
  },
};

const customSortSelectStyles = {
  control: (styles) => ({
    ...styles,
    direction: "rtl",
    backgroundColor: "#FFDA83",
    borderColor: "#FFDA83",
    boxShadow: "0 0 0 1px #FFDA83",
    ":hover": {
      borderColor: "#FFDA83",
    },
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "#001E5D",
  }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: "#001E5D",
    padding: "8px 4px",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "unset",
  }),
  clearIndicator: (styles) => ({
    ...styles,
    color: "#001E5D",
    padding: "8px 4px",
  }),
  menu: (styles) => ({
    ...styles,
    marginTop: "0.3rem",
    backgroundColor: "#F0F2F5",
    borderRadius: "0 0 10px 10px",
    border: "none",
    boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.23)",
  }),
  menuList: (styles) => ({
    ...styles,
    padding: "1rem 0.5rem",
  }),
  option: (styles, state) => ({
    ...styles,
    color: "#515151",
    borderRadius: "6px",
    backgroundColor: state.isSelected ? "#DBE8FE" : "#d8d8d8",
    marginBottom: "0.3rem",
    ":hover": {
      backgroundColor: state.isSelected ? "#c1d7fc" : "#DBE8FE",
    },
  }),
};

const customFilterSelectStyles = {
  control: (styles) => ({
    ...styles,
    direction: "rtl",
    backgroundColor: "#BFE8FB",
    borderColor: "#BFE8FB",
    boxShadow: "0 0 0 1px #BFE8FB",
    ":hover": {
      borderColor: "#BFE8FB",
    },
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "#001E5D",
  }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: "#001E5D",
    padding: "8px 4px",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "unset",
  }),
  clearIndicator: (styles) => ({
    ...styles,
    color: "#001E5D",
    padding: "8px 4px",
  }),
  menu: (styles) => ({
    ...styles,
    marginTop: "0.3rem",
    backgroundColor: "#F0F2F5",
    borderRadius: "0 0 10px 10px",
    border: "none",
    boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.23)",
  }),
  menuList: (styles) => ({
    ...styles,
    padding: "1rem 0.5rem",
  }),
  option: (styles, state) => ({
    ...styles,
    color: "#515151",
    borderRadius: "6px",
    backgroundColor: state.isSelected ? "#DBE8FE" : "#d8d8d8",
    marginBottom: "0.3rem",
    ":hover": {
      backgroundColor: state.isSelected ? "#c1d7fc" : "#DBE8FE",
    },
  }),
};

const FilterCustomControl = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      <span style={{ marginRight: "0.7rem" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 23.837 15.892"
          width="23.837"
          height="15.892"
        >
          <path
            d="M0 15.892h7.946v-2.649H0zM0 0v2.649h23.837V0zm0 9.27h15.892V6.621H0z"
            fill="#bbb9b9"
          />
        </svg>
      </span>
      {children}
    </components.Control>
  );
};

const SortCustomControl = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      <span style={{ marginRight: "0.7rem" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 26 17.333"
          width="26"
          height="17.333"
        >
          <path
            d="M10.111 17.333h5.778v-2.888h-5.778zM0 0v2.889h26V0zm4.333 10.111h17.334V7.222H4.333z"
            fill="#bbb9b9"
          />
        </svg>
      </span>
      {children}
    </components.Control>
  );
};

export class Forwarder extends Component {
  static displayName = Forwarder.name;

  form;
  search;

  constructor(props) {
    super(props);

    this.state = {
      form: {
        search: "",
      },
      excelList: [],
      offerList: [],
      offerModalVisible: false,
      offerIcon: "",
      offerTitle: "",
      offerNote: "",
      offerCompany: "",
      offerColor: "#ffffff",

      excelItems: [],
      loadType: [],
      sourcePort: [],
      targetPort: [],
      containerType: [],
      containerCount: [],
      serviceList: [],
      offerList: [],
      portList: [],
      offerModalVisible: false,
      showLoading: false,
      sortItem: "0",
      filterItem: null,
      selectedLoadType: "_All",
      selectedSourcePort: "_All",
      selectedTargetPort: "_All",
      selectedContainerType: "_All",
      selectedContainerCount: "_All",
      showMessage: false,
      resultMessage: "",
      msgColor: "#FFFFEE",
    };

    this.form = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    // this.getExcelItems();
    this.getOfferList();
    this.getPortList();
    // this.getAllServiceData();
  }

  getPortList() {
    Axios.get(API_URL + "Port/getAllPorts")
      .then((response) => {
        this.setState({ portList: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getOfferList() {
    Axios.get(API_URL + "Offer")
      .then((response) => {
        this.setState({ offerList: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getExcelItems() {
    Axios.get(API_URL + "Excel/getExcelItems")
      .then((response) => {
        this.setState({ excelItems: response.data });
        this.setState({ loadType: response.data.loadType });
        this.setState({ sourcePort: response.data.sourcePort });
        this.setState({ targetPort: response.data.targetPort });
        this.setState({ containerType: response.data.containerType });
        this.setState({ containerCount: response.data.containerCount });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getAllServiceData() {
    Axios.get(API_URL + "Service/getAllServiceData")
      .then((response) => {
        this.setState({ serviceList: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleChange(event) {
    this.setState({
      form: {
        ...this.state.form,
        [event.target.id]: event.target.value,
      },
    });
  }

  handleFilterChange = (option) => {
    this.setState({ filterItem: option?.value ?? null });
  };

  handleClearLoadType() {
    this.setState({ showMessage: false });
    this.setState({ resultMessage: "" });
    this.setState({ selectedLoadType: "_All" });
  }

  handleClearSourcePort() {
    this.setState({ showMessage: false });
    this.setState({ resultMessage: "" });
    this.setState({ selectedSourcePort: "_All" });
  }

  handleClearTargetPort() {
    this.setState({ showMessage: false });
    this.setState({ resultMessage: "" });
    this.setState({ selectedTargetPort: "_All" });
  }

  handleClearContainerType() {
    this.setState({ showMessage: false });
    this.setState({ resultMessage: "" });
    this.setState({ selectedContainerType: "_All" });
  }

  handleClearContainerCount() {
    this.setState({ showMessage: false });
    this.setState({ resultMessage: "" });
    this.setState({ selectedContainerCount: "_All" });
  }

  handleChangeLoadType = (values) => {
    values.map((value) => {
      this.setState({ selectedLoadType: value.id });
    });
    this.setState({ showMessage: false });
    this.setState({ resultMessage: "" });
  };

  handleChangeSourcePort = (values) => {
    values.map((value) => {
      this.setState({ selectedSourcePort: value.id });
    });
    this.setState({ showMessage: false });
    this.setState({ resultMessage: "" });
  };

  handleChangeTargetPort = (values) => {
    values.map((value) => {
      this.setState({ selectedTargetPort: value.id });
    });
    this.setState({ showMessage: false });
    this.setState({ resultMessage: "" });
  };

  handleChangeContainerType = (values) => {
    values.map((value) => {
      this.setState({ selectedContainerType: value.id });
    });
    this.setState({ showMessage: false });
    this.setState({ resultMessage: "" });
  };

  handleChangeContainerCount = (values) => {
    values.map((value) => {
      this.setState({ selectedContainerCount: value.id });
    });
    this.setState({ showMessage: false });
    this.setState({ resultMessage: "" });
  };

  onSubmit(eventData) {
    eventData.preventDefault();
    var srcPort = this.state.selectedSourcePort;
    var trgtPort = this.state.selectedTargetPort;

    if (srcPort !== "_All" && trgtPort !== "_All") {
      this.setState({ showLoading: true });
      const search = {
        LoadType: this.state.selectedLoadType,
        SourcePort: srcPort,
        TargetPort: trgtPort,
        ContainerType: this.state.selectedContainerType,
        // ContainerCount: this.state.selectedContainerCount,
      };

      this.setState({ serviceList: [] });
      Axios.post(API_URL + "Excel/getServiceData", search)
        .then((response) => {
          this.setState({ serviceList: response.data });
          this.setState({ showLoading: false });
          this.setState({ showMessage: true });
          this.setState({ msgColor: "#008800" });
          this.setState({
            resultMessage: this.state.serviceList.length + " مورد ",
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ showLoading: false });
        });
    } else {
      this.setState({ showMessage: true });
      this.setState({ msgColor: "#FF0000" });
      this.setState({
        resultMessage: "انتخاب بندر مبدأ و بندر مقصد الزامی است",
      });
    }
  }

  goToAddService() {
    const { history } = this.props;
    history.push("/addService");
  }

  goToAddOffer() {
    const { history } = this.props;
    history.push("/addOffer");
  }

  loadOfferInfo(id) {
    Axios.get(API_URL + "Offer/" + id)
      .then((response) => {
        this.setState({ offerTitle: response.data.title });
        this.setState({ offerNote: response.data.description });
        this.setState({ offerCompany: response.data?.company ?? "" });
        this.setState({ offerColor: response.data.color });
        this.setState({ offerIcon: response.data.icon });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  showOfferModal(id) {
    this.setState({ offerModalVisible: true });
    this.loadOfferInfo(id);
  }

  closeOfferModal() {
    this.setState({ offerModalVisible: false });
  }

  handleSortChange = (option) => {
    const sortValue = option?.value ?? "0";

    switch (sortValue) {
      case "1": {
        const newServiceList = this.state.serviceList.sort((a, b) => {
          return a.servicePrice - b.servicePrice;
        });

        this.setState({ serviceList: newServiceList });

        break;
      }
      case "2": {
        const newServiceList = this.state.serviceList.sort((a, b) => {
          if (a.serviceCredit < b.serviceCredit) return -1;
          if (a.serviceCredit > b.serviceCredit) return 1;
          return 0;
        });

        this.setState({ serviceList: newServiceList });

        break;
      }
      case "3": {
        const newServiceList = this.state.serviceList.sort((a, b) => {
          if (a.serviceName < b.serviceName) return -1;
          if (a.serviceName > b.serviceName) return 1;
          return 0;
        });

        this.setState({ serviceList: newServiceList });

        break;
      }
      default:
        // do nothing (:
        break;
    }
  };

  render() {
    const { serviceList, offerList, filterItem, portList } = this.state;

    const styles = {
      wrapDrpdwns: {
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "row-reverse",
        textAlign: "center",
      },
      boxDrpdown: {
        width: "17%",
        backgroundColor: "gray",
        display: "inline-block",
        border: "1px solid #ededed",
      },
      inputText: {
        height: 60,
      },
      drpdown: {
        padding: "5px",
        height: 69,
        width: "100%",
        border: "0px",
        borderRadius: 0,
        fontSize: "16px",
        backgroundColor: "#ffffff",
      },
    };

    const images = {
      "icn1.png": require("../../assets/images/icn1.png"),
      "icn2.png": require("../../assets/images/icn2.png"),
      "icn3.png": require("../../assets/images/icn3.png"),
    };

    const loadTypes = [
      { id: "GENERAL CARGO", title: "بارعمومی/GENERAL CARGO" },
      { id: "DANGEROUS CARGO", title: "بارخطرناک/DANGEROUS CARGO" },
      { id: "LCL", title: "خرده بار/LCL" },
    ];

    const containerTypes = [
      { id: "20GP", title: "20GP" },
      { id: "40GP/HC", title: "40GP/HC" },
      { id: "20RF", title: "20RF" },
      { id: "40RF", title: "40RF" },
      { id: "OPEN TOP", title: "OPEN TOP" },
      { id: "FLAT RACK", title: "FLAT RACK" },
      { id: "ISO TANK", title: "ISO TANK" },
      { id: "SOC 20GP", title: "SOC 20GP" },
      { id: "SOC 40GP/HC", title: "SOC 40GP/HC" },
    ];

    const containerNums = [
      { id: "1-5", title: "1-5" },
      { id: "5-20", title: "5-20" },
      { id: "20-50", title: "20-50" },
      { id: "50-100", title: "50-100" },
      { id: "بالای 100", title: "بالای 100" },
    ];

    const filteredServicesList = filterItem
      ? serviceList.filter((item) => item.serviceType == filterItem)
      : serviceList;

    return (
      <div className="outerCntnt">
        <div style={{ width: "100%", textAlign: "right", paddingTop: 50 }}>
          <div style={{ float: "right", width: "100%", paddingLeft: 15 }}>
            <div>
              <span className="special-recommends-title">
                {" "}
                پیشنهاد ویژه برای بازرگانان{" "}
              </span>
              <img
                src={require("../../assets/images/percent.png")}
                width="40"
                alt=""
                style={{ margin: 20 }}
              />
            </div>

            <Card className="cardStyle" style={{ height: "auto", padding: 10 }}>
              <CardBody>
                <div
                  style={{
                    width: "100%",
                    height: 370,
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      width: "25%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "0px solid #007700",
                    }}
                  >
                    <img
                      src={require("../../assets/images/notedark.png")}
                      width="90"
                      alt=""
                      style={{ marginBottom: 20 }}
                    />
                    <Button
                      text="افزودن سرویس های ویژه"
                      type="submit"
                      classes="btnBlueBox"
                      width="200px"
                      onClickFn={this.goToAddService.bind(this)}
                    />
                    <Button
                      text="افزودن پیشنهادات ویژه"
                      type="submit"
                      classes="btnOrangeBox"
                      width="200px"
                      onClickFn={this.goToAddOffer.bind(this)}
                    />
                  </div>
                  <div
                    style={{
                      width: "75%",
                      display: "flex",
                      flexDirection: "column",
                      overflowY: "scroll",
                      paddingRight: 10,
                      border: "0px solid #007700",
                    }}
                  >
                    <div className="offerContainer">
                      {offerList.map((item, i) => {
                        return (
                          <Card
                            key={i}
                            className="special-recommends-card"
                            style={{ backgroundColor: item.color }}
                          >
                            <CardBody>
                              <div className="box-content">
                                <span className="title" style={{width:200,whiteSpace:"normal"}}>{item.title}</span>
                                <span className="description">
                                  {item.company}
                                </span>
                                <Button
                                  text="بیشتر"
                                  type="submit"
                                  classes="btnWhiteNormal btn-small-rounded"
                                  width="60px"
                                  onClickFn={() => this.showOfferModal(item.id)}
                                />
                              </div>
                              <img src={images[item.icon]} width="60" />
                            </CardBody>
                          </Card>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>

            <div style={{ margin: 20 }}>
              <span className="bar-situation-title">
                لطفا شرایط استعلام خود را وارد نمایید
              </span>
            </div>

            <Card
              className="cardStyle black-shadow"
              style={{ height: "100%", padding: 0 }}
            >
              <CardBody
                style={{
                  padding: 0,
                  borderTopLeftRadius: 7,
                  borderTopRightRadius: 7,
                  overflow: "hidden",
                }}
              >
                <ValidatorForm ref="form" onSubmit={() => {}}>
                  <div style={styles.wrapDrpdwns}>
                    <div style={styles.boxDrpdown}>
                      <Select
                        className="dropSelect"
                        style={styles.drpdown}
                        searchable="true"
                        placeholder="نوع بار..."
                        options={loadTypes}
                        direction="rtl"
                        labelField="title"
                        valueField="id"
                        searchBy="title"
                        sortBy="title"
                        clearable="true"
                        clearOnSelect="true"
                        clearOnBlur="true"
                        onClearAll={() => this.handleClearLoadType(this)}
                        onChange={(values) => this.handleChangeLoadType(values)}
                        noDataLabel="موردی پیدا نشد"
                      />
                    </div>
                    <div style={styles.boxDrpdown}>
                      <Select
                        className="dropSelect"
                        style={styles.drpdown}
                        searchable="true"
                        placeholder="بندر مبداء..."
                        options={portList}
                        direction="rtl"
                        labelField="title"
                        valueField="title"
                        searchBy="title"
                        sortBy="title"
                        clearable="true"
                        clearOnSelect="true"
                        clearOnBlur="true"
                        onClearAll={() => this.handleClearSourcePort(this)}
                        onChange={(values) =>
                          this.handleChangeSourcePort(values)
                        }
                        noDataLabel="موردی پیدا نشد"
                      />
                    </div>
                    <div style={styles.boxDrpdown}>
                      <Select
                        style={styles.drpdown}
                        searchable="true"
                        placeholder="بندر مقصد..."
                        options={portList}
                        direction="rtl"
                        labelField="title"
                        valueField="title"
                        searchBy="title"
                        sortBy="title"
                        clearable="true"
                        clearOnSelect="true"
                        clearOnBlur="true"
                        onClearAll={() => this.handleClearTargetPort(this)}
                        onChange={(values) =>
                          this.handleChangeTargetPort(values)
                        }
                        noDataLabel="موردی پیدا نشد"
                      />
                    </div>
                    <div style={styles.boxDrpdown}>
                      <Select
                        className="dropSelect"
                        style={styles.drpdown}
                        searchable="true"
                        placeholder="نوع کانتینر..."
                        options={containerTypes}
                        direction="rtl"
                        labelField="title"
                        valueField="id"
                        searchBy="title"
                        sortBy="title"
                        clearable="true"
                        clearOnSelect="true"
                        clearOnBlur="true"
                        onClearAll={() => this.handleClearContainerType(this)}
                        onChange={(values) =>
                          this.handleChangeContainerType(values)
                        }
                        noDataLabel="موردی پیدا نشد"
                      />
                    </div>
                    <div style={styles.boxDrpdown}
                    style={{backgroundColor:"white",width:"20%"}}
                    >
                      {/* <Select
                        className="dropSelect"
                        style={styles.drpdown}
                        searchable="true"
                        placeholder="تعداد کانتینر..."
                        options={containerNums}
                        direction="rtl"
                        labelField="title"
                        valueField="id"
                        searchBy="title"
                        sortBy="title"
                        clearable="true"
                        clearOnSelect="true"
                        clearOnBlur="true"
                        onClearAll={() => this.handleClearContainerCount(this)}
                        onChange={(values) =>
                          this.handleChangeContainerCount(values)
                        }
                        noDataLabel="موردی پیدا نشد"
                      /> */}
                    </div>
                    <div
                      style={{
                        width: "10%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {this.state.showLoading ? <Spinner color="gray" /> : null}
                    </div>
                    <div
                      onClick={this.onSubmit}
                      style={{
                        width: "5%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: 70,
                        backgroundColor: "#165DF5",
                        borderTopLeftRadius: 5,
                        borderBottomLeftRadius: 5,
                        cursor: "pointer",
                      }}
                    >
                      <Icon icon={"search"} size={25} color="white" />
                    </div>
                  </div>
                </ValidatorForm>
                <div className="table-filter-wrapper">
                  <Table borderless>
                    <tbody>
                      <tr>
                        <td
                          style={{
                            width: "65%",
                            direction: "rtl",
                            paddingLeft: 30,
                            paddingTop: 10,
                          }}
                        >
                          <div
                            style={{
                              float: "left",
                              width: "fit-content",
                              height: 40,
                              backgroundColor: "#FFFFDD",
                              padding: "0 10px",
                              display: this.state.showMessage ? "flex" : "none",
                              alignItems: "center",
                              borderRadius: 6,
                            }}
                          >
                            <span
                              style={{
                                fontSize: "12px",
                                color: this.state.msgColor,
                              }}
                            >
                              {this.state.resultMessage}
                            </span>
                          </div>
                        </td>
                        <td style={{ width: "15%" }}>
                          <RCSelect
                            placeholder="فیلتر"
                            isClearable
                            onChange={this.handleFilterChange}
                            styles={customFilterSelectStyles}
                            components={{ Control: FilterCustomControl }}
                            options={[
                              {
                                value: "Carrier",
                                label: "Carrier",
                              },
                              {
                                value: "Forwarder",
                                label: "Forwarder",
                              },
                            ]}
                          />
                        </td>
                        <td style={{ width: "15%" }}>
                          <RCSelect
                            placeholder="مرتب سازی"
                            isClearable
                            onChange={this.handleSortChange}
                            styles={customSortSelectStyles}
                            components={{ Control: SortCustomControl }}
                            options={[
                              {
                                value: "1",
                                label: "قیمت",
                              },
                              {
                                value: "2",
                                label: "تاریخ اعتبار",
                              },
                              {
                                value: "3",
                                label: "اسم کشتیرانی",
                              },
                            ]}
                          />
                        </td>
                        <td style={{ width: "5%" }} />
                      </tr>
                    </tbody>
                  </Table>
                </div>
                <div className="table-wrapper">
                  <Table hover>
                    <thead>
                      <tr>
                        <th style={{ width: "15%", textAlign: "center" }}>
                          نوع سرویس
                        </th>
                        <th style={{ width: "25%", textAlign: "center" }}>
                          اسم کشتیرانی
                        </th>
                        <th style={{ width: "10%", textAlign: "center" }}>
                          قیمت پیشنهادی
                        </th>
                        <th style={{ width: "20%", textAlign: "center" }}>
                          اعتبار قیمت
                        </th>
                        <th style={{ width: "10%", textAlign: "center" }}>
                          اطلاعات تماس
                        </th>
                        <th style={{ width: "20%", textAlign: "center" }}>
                          آدرس ایمیل
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ backgroundColor: "#FFFFFF" }}>
                      {filteredServicesList && filteredServicesList.length ? (
                        filteredServicesList.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                }}
                              >
                                {item.serviceType}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                }}
                              >
                                {item.serviceName}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                }}
                              >
                                {item.servicePrice}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                }}
                              >
                                {item.serviceCredit}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                }}
                              >
                                {item.servicePhone}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                }}
                              >
                                {item.serviceEmail}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td
                            colSpan="6"
                            style={{ textAlign: "center", direction: "rtl" }}
                          >
                            نتیجه ای یافت نشد!
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
            <br />
            <br />
          </div>
        </div>

        {/* Offer Modal */}
        <Modal isOpen={this.state.offerModalVisible} style={customStyles}>
          <div className="offer-modal-body">
            <h3 className="title">{this.state.offerTitle}</h3>
            <span className="company-name">{this.state.offerCompany}</span>

            <div className="description-area">
              <div className="description-content">
                <span>توضیحات</span>
                <div>{HtmlParser(this.state.offerNote)}</div>
              </div>
              <div className="icon-holder">
                <img src={images[this.state.offerIcon]} width="130" />
                <Button
                  text="بستن"
                  type="button"
                  onClickFn={this.closeOfferModal.bind(this)}
                />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
