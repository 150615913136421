import React, { Component } from "react";
import { Card, CardBody, Row, Col, Input } from "reactstrap";
import { ValidatorForm } from "react-form-validator-core";
import Button from "../General/Button";
import TextValidator from "../General/TextValidator";
import "../../App.css";

export class Contact extends Component {
  static displayName = Contact.name;

  constructor(props) {
    super(props);

    this.state = {
      form: {
        fullname: "",
        mobile: "",
        email: "",
        subject: "",
        message: "",
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({
      form: {
        ...this.state.form,
        [event.target.id]: event.target.value,
      },
    });
  }

  handleSubmit() {}

  goToHome() {
    const { history } = this.props;
    history.push("/");
  }

  render() {
    return (
      <div className="outerCntnt">
        <div
          className="centerContent"
          style={{
            width: 1000,
            textAlign: "right",
            paddingTop: 10,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <div
            style={{ width: 1100, transform: "scale(0.85)", marginRight: -150 }}
          >
            <Card
              className="cardStyle inner-pages-card"
              style={{ padding: "4rem 6rem" }}
            >
              <CardBody>
                <Row>
                  <Col className="col-md-6">
                    <h2>فرم تماس</h2>
                    <p>
                      از طریق فرم زیر، ولگا را از پیشنهادات و انتقادات خود مطلع
                      نمایید.
                    </p>
                    <ValidatorForm onSubmit={this.handleSubmit}>
                      <TextValidator
                        type="text"
                        name="fullname"
                        id="fullname"
                        placeholder="نام و نام خانوادگی"
                        value={this.state.form.fullname}
                        handleChange={this.handleChange}
                        validators={["required", "maxStringLength:20"]}
                        errorMessages={[
                          "نام و نام خانوادگی را وارد کنید",
                          "حداکثر باید 20 کاراکتر باشد",
                        ]}
                      />
                      <TextValidator
                        type="text"
                        name="mobile"
                        id="mobile"
                        placeholder="شماره موبایل"
                        value={this.state.form.mobile}
                        handleChange={this.handleChange}
                      />
                      <TextValidator
                        type="email"
                        name="email"
                        id="email"
                        placeholder="ایمیل"
                        value={this.state.form.email}
                        handleChange={this.handleChange}
                        validators={["required", "isEmailAddress"]}
                        errorMessages={[
                          "ایمیل را وارد کنید",
                          " آدرس ایمیل نا معتبر می باشد",
                        ]}
                      />
                      <TextValidator
                        type="text"
                        name="subject"
                        id="subject"
                        placeholder="موضوع"
                        value={this.state.form.subject}
                        handleChange={this.handleChange}
                        validators={["required"]}
                        errorMessages={["موضوع را وارد کنید"]}
                      />
                      <Input
                        type="textarea"
                        name="message"
                        id="message"
                        placeholder="متن پیام"
                        value={this.state.form.message}
                        onChange={this.handleChange}
                      />
                      <Button
                        text="ثبت"
                        type="submit"
                        classes="btnBlueNormal"
                        styles={{ marginTop: "1rem" }}
                        loading={this.state.showLoading}
                      />
                      <Button
                        text="بازگشت"
                        type="submit"
                        classes="btnOutLineNormal"
                        styles={{ marginTop: "1rem" }}
                        onClickFn={this.goToHome.bind(this)}
                      />
                    </ValidatorForm>
                  </Col>
                  <Col
                    className="col-md-6"
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      paddingBottom: "7.5rem",
                      paddingLeft: "3rem",
                    }}
                  >
                    <div>
                      <h1>تماس با ما</h1>
                      <p>
                        <b>نشانی دفتر مرکزی:</b>
                        میرداماد، میدان مادر، خیابان شاه نظری، خیابان مظفری
                        (سپاهان سابق)، پلاک 26 ساختمان ترمه،
                      </p>
                      <p>
                        <b>شماره تماس:</b>
                        24521323
                      </p>
                      <p>
                        <b>پست الکترونیکی:</b>
                        info@volgarates.com
                      </p>
                      <p>
                        <b>تماس با مدیریت:</b>
                        zand@volgarates.com
                      </p>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}
