import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import { ValidatorForm } from "react-form-validator-core";
import OtpInput from "react-otp-input";
import Button from "../General/Button";
import Axios from "axios";
import API_URL from "../Api/ApiUrl";
import Countdown from "react-countdown";
import "../../App.css";

export class CodeVerify extends Component {
  static displayName = CodeVerify.name;

  form;
  smscodeRef;
  constructor(props) {
    super(props);

    this.vcode = "0";

    this.state = {
      form: {
        smscode: "",
      },
      errorMessage: "",
      pageLoading: true,
      showLoading: false,
      btnStatus: "disabled",
      btnClassName: "btnOutLineGray",
      otp: "",
      waitTime: Date.now() + 60000,
      actionType: "forget",
      objectModel: "",
    };

    this.form = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const sentParams = this.props.history.location.state;
    var mobile = sentParams.mobileNumber;
    Axios.get(API_URL + "User/sendSMS/" + mobile)
      .then((response) => {
        this.vcode = response.data.vcode;
        this.setState({ pageLoading: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ pageLoading: false });
      });
  }

  handleChange(event) {
    this.setState({ errorMessage: "" });
    this.setState({
      form: {
        ...this.state.form,
        [event.target.id]: event.target.value,
      },
    });
  }

  handleChangeOtp = (otp) => {
    this.setState({ otp });
    this.setState({ errorMessage: "" });
  };

  async handleSubmit(event) {
    if (this.state.otp === "" || this.state.otp.length < 4) {
      this.setState({ showLoading: false });
      this.setState({ errorMessage: "کد وارد نشده است" });
      return;
    } else {
      //check code
      if (this.state.otp === this.vcode) {
        const reqParams = this.props.history.location.state;
        this.setState({ actionType: reqParams.actionType });
        this.setState({ objectModel: reqParams.objectMdl });

        //for account
        if (reqParams.actionType === "acount") {
          Axios.post(API_URL + "Account", reqParams.objectMdl)
            .then((res) => {
              var msg = res.data.message;
              if (msg === "ok") {
                localStorage.setItem("access", 1);
                localStorage.setItem("userId", 0);
                localStorage.setItem("userName", "");
                localStorage.setItem("nameFamily", "");
                localStorage.setItem("roleId", 6);
                localStorage.setItem("roleTitle", "free");
                sessionStorage.setItem("isLoggedIn", true);
                localStorage.setItem("userCode", "");
                this.props.history.push("/merchant");
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }

        //for forget
        if (reqParams.actionType === "forget") {
          const { history } = this.props;
          history.push({
            pathname: "/forgetStep2",
            state: { mobileNumber: reqParams.mobileNumber },
          });
        }
      } else {
        this.setState({ errorMessage: "کد وارد نشده است" });
      }
    }
  }

  goToSignup() {
    const { history } = this.props;
    history.push("/signup");
  }

  goToStart() {
    const { history } = this.props;
    history.push("/");
  }

  setRetryButton() {
    this.setState({ waitTime: 0 });
    this.setState({ btnStatus: "" });
    this.setState({ btnClassName: "btnOutLineNormal" });
  }

  render() {
    const renderer = ({ seconds }) => {
      return <span>{seconds}</span>;
    };
    return (
      <div className="outerCntnt">
        <div className="innerCntnt">
          <div style={{ width: "100%", textAlign: "right", paddingTop: 25 }}>
            <div style={{ float: "right", width: 450, paddingLeft: 15 }}>
              <Card className="cardStyle auth-card">
                <CardBody>
                  {this.state.pageLoading ? (
                    <p style={{ textAlign: "center", direction: "rtl" }}>
                      درحال ارسال پیامک...
                    </p>
                  ) : (
                    <>
                      <div
                        style={{
                          textAlign: "center",
                          marginBottom: 20,
                          direction: "rtl",
                        }}
                      >
                        <img
                          src={require("../../assets/images/padlock.png")}
                          width="60"
                          alt=""
                        />
                        <div style={{ width: 100, height: 10 }}></div>
                        <span style={{ fontSize: 14 }}>
                          کد ارسال شده از طریق sms را وارد کنید
                        </span>
                      </div>
                      <div className="welButn" style={{ textAlign: "center" }}>
                        <ValidatorForm onSubmit={this.handleSubmit}>
                          <div className="otp-input-wrapper">
                            <OtpInput
                              inputStyle="textNumber"
                              value={this.state.otp}
                              onChange={this.handleChangeOtp}
                              numInputs={4}
                              isInputNum={true}
                              separator={<span> </span>}
                            />
                          </div>
                          <div style={{ width: 100, height: 20 }}></div>
                          <Countdown
                            date={this.state.waitTime}
                            renderer={renderer}
                            onComplete={this.setRetryButton.bind(this)}
                          />
                          {this.state.errorMessage ? (
                            <div
                              style={{
                                color: "#cc0000",
                                padding: 15,
                                textAlign: "center",
                              }}
                            >
                              {this.state.errorMessage}
                            </div>
                          ) : null}
                          <div style={{ width: 100, height: 10 }}></div>
                          <div className="m-t-30 text-center">
                            <Button
                              text="ورود"
                              type="submit"
                              classes="btnBlueNormal"
                              loading={this.state.showLoading}
                            />
                          </div>
                          <div style={{ width: 100, height: 10 }}></div>
                          <div className="m-t-30 text-center">
                            <Button
                              text="تلاش مجدد"
                              type="button"
                              classes={this.state.btnClassName}
                              disabled={this.state.btnStatus}
                            />
                          </div>
                          <div style={{ width: 100, height: 10 }}></div>
                          <div className="m-t-30 text-center">
                            <Button
                              text="بازگشت"
                              type="submit"
                              classes="btnOutLineNormal"
                              onClickFn={this.goToStart.bind(this)}
                            />
                          </div>
                        </ValidatorForm>
                      </div>
                    </>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
