import React, { Component } from "react";
import Icon from "../Icons/Icon";

const AppContext = React.createContext();

export class UserAvatar extends Component {
  static displayName = UserAvatar.name;

  constructor(props) {
    super(props);

    this.state = {
      avatarBase64: this.props.avatarBase64,
    };
  }

  render() {
    return (
      // <AppContext.Provider value={{ avatarBase64: '' }}>
      <div>
        {this.props.avatarBase64 ? (
          <img
            src={`${this.props.avatarBase64}`}
            className="userAvatar"
            alt=""
          />
        ) : (
          <span className="avatar-default">
            <Icon icon="user" size={15} />
          </span>
        )}
      </div>
      // {/* </AppContext.Provider> */}
    );
  }
}
