import React from "react";
import Icon from "../Icons/Icon";

class ButtonPlus extends React.Component {
  render() {
    return (
      <div className="butnPlus">
        <div
          style={{
            position: "relative",
            float: "left",
            top: -7,
            left: -6,
            cursor: "pointer",
          }}
        >
          <Icon icon={"add_box"} size={45} color="#165DF5" />
        </div>
        <div style={{ width: "auto", float: "right", padding: 5 }}>
          اضافه کردن افراد
        </div>
      </div>

      //   <button
      //     onClick={this.props.onClickFn}
      //     className={`${this.props.classes} base-btn m-l-15 ${!!this.props.disabled ? "button-disabled":""}` }
      //     type={this.props.type}
      //     disabled={this.props.disabled}
      //     style={{
      //       // backgroundColor: this.props.backgroundColor,
      //       // color: this.props.color,
      //       width: this.props.width
      //     }}>
      //     {this.props.text}
      //   </button>
    );
  }
}
export default ButtonPlus;
