import React, { Component } from "react";
import Axios from "axios";
import API_URL from "../Api/ApiUrl";
import moment from "jalali-moment";
import { Card, CardBody } from "reactstrap";
import { ValidatorForm } from "react-form-validator-core";
import TextValidator from "../General/TextValidator";
import { isEmailAddress, isMobileNumber } from "../Utils/Utility";
import Button from "../General/Button";
import TermsModal from "../Terms/TermsModal";
import "../../App.css";

export class SignupCarrier extends Component {
  static displayName = SignupCarrier.name;

  form;
  name;
  family;
  mobile;
  email;
  count;
  password;
  constructor(props) {
    super(props);

    this.state = {
      form: {
        name: "",
        family: "",
        mobile: "",
        email: "",
        count: 0,//this is not our point******
        password: "",
        password2: "",
      },
      termsOpen: false,
      terms: false,
      btnStatus: "disabled",
      btnClassName: "btnBlueDisabled",
      chkbxAccType1: 0,
      chkbxAccType2: 0,
      membership: 0,
      regType: 1,
      resultMessage: "",
      showLoading: false,
      comparePswrd: false,pathname:"/confirmPersonal"
    };

    this.form = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    ValidatorForm.addValidationRule("isMobileNumber", (value) =>
      isMobileNumber(value)
    );
    ValidatorForm.addValidationRule("isEmailAddress", (value) =>
      isEmailAddress(value)
    );

    Axios.get(API_URL + "User/getItemAmount/carrier")
      .then((response) => {
        this.setState({ membership: response.data.itemValue });
        
      })
      .catch((error) => {
        console.log(error);
        
      });
  }

  handleChange(event) {
    this.setState({ comparePswrd: false });
    this.setState({ resultMessage: "" });
    this.setState({
      form: {
        ...this.state.form,
        [event.target.id]: event.target.value,
      },
    });
  }

  async handleSubmit() {
    var pswrd1 = this.state.form.password;
    var pswrd2 = this.state.form.password2;

    if (pswrd1 != pswrd2) {
      this.setState({ comparePswrd: true });
      return;
    }
    this.setState({ showLoading: true });
    const sellerCount = Number(this.state.form.count);
    const carrier = {
      Id: 0,
      Name: this.state.form.name,
      Family: this.state.form.family,
      Mobile: this.state.form.mobile,
      Email : this.state.form.email,
      NationalNo: "",
      IdentityNo: "",
      Company: "",
      RegisterNo: "",
      EconomicNo: "",
      RegType: this.state.regType,
      ProviderType: "Carrier",
      Count:sellerCount,
      RegDate: moment().locale("fa").format("YYYY/MM/DD"),
      Status: 0,
      password: this.state.form.password
    };

    Axios.post(API_URL + "Provider", carrier)
      .then((res) => {
        const message = res.data.message;
        // alert(res)
        if (message === "ok") {
          const authority = res.data.authority;
          // const payId = res.data.payId;
          const userId = res.data.userId;
          const merchantId = res.data.id;

          localStorage.clear();
          setTimeout(() => {
            // localStorage.setItem("payId", payId);
            localStorage.setItem("userId", userId);
            localStorage.setItem("merchantId", merchantId);
            localStorage.setItem("sellerCount", sellerCount);
            // localStorage.setItem("regType", this.state.regType.toString());
            localStorage.setItem("utype", "provider");

             const utype = localStorage.getItem("utype");
            const signupType = utype === "merchant" ? 1 : utype === "provider" ? 2 : null;
            const pathname = this.state.pathname
            // this.state.regType.toString === "2" ? "/confirmCompany" : "/confirmPersonal";
            // window.location = `https://volgarates.com/SignupForwarder`;
            // console.log(pathname)
            this.props.history.push({
              pathname,
              signupType,
              merchantId,
              action: Number(sellerCount) > 0 ? "add" : "none",
            });
            
          });
        } else if (message === "exist") {
          // console.log(res)
          this.setState({
            resultMessage: "کاربری با این ایمیل قبلاً ثبت شده است",
          });
        }
        this.setState({ showLoading: false });
      })
      .catch((error) => {
        
        console.log(error);
      });
  }

  onRegTypeChange = (value) => {
    
    this.setState({ regType: value });
    // console.log(this.state.regType)
  };

  onCheckBoxChanged(e) {
    let isChecked = e.target.checked;
    if (isChecked) {
      this.setState({ btnStatus: "" });
      this.setState({ btnClassName: "btnBlueNormal" });
    } else {
      this.setState({ btnStatus: "disabled" });
      this.setState({ btnClassName: "btnBlueDisabled" });
    }
  }

  onChckBxAcc1Changed(e) {
    let isChecked = e.target.checked;
    if (isChecked) {
      this.setState({ chkbxAccType1: 1 });
    } else {
      this.setState({ chkbxAccType1: 0 });
    }
  }

  onChckBxAcc2Changed(e) {
    let isChecked = e.target.checked;
    if (isChecked) {
      this.setState({ chkbxAccType2: 1 });
    } else {
      this.setState({ chkbxAccType2: 0 });
    }
  }

  goToSignup() {
    const { history } = this.props;
    history.push("/signup");
  }

  render() {
    const ColoredLine = ({ color, width }) => (
      <hr
        style={{
          color: color,
          backgroundColor: color,
          width: width,
          height: 1,
        }}
      />
    );
    return (
      <div
        style={{
          width: "auto",
          float: "right",
          display: "flex",
          justifyContent: "flex-end",
          alignSelf: "flex-end",
          alignItems: "flex-end",
          border: "0px solid #00ee00",
          marginRight: -50,
        }}
      >
        <div style={{ margin: 100 }}>
          <img
            src={require("../../assets/images/signupWhiteIcon2.png")}
            width="220"
            alt=""
          />
        </div>
        <div
          style={{
            width: 930,
            transform: "scale(0.85)",
            textAlign: "right",
            paddingTop: 25,
          }}
        >
          <ValidatorForm onSubmit={this.handleSubmit}>
            <Card className="cardStyle dark-shadow signup-big-card">
              <CardBody>
                <div className="row signup-columns">
                  <div
                    className="col-6"
                    style={{ borderLeft: "1px solid #E6E5E5" }}
                  >
                    <div className="column-body">
                      <div style={{ marginBottom: 10 }}>
                        <span
                          style={{
                            verticalAlign: "middle",
                            fontSize: 15,
                            fontWeight: "bold",
                          }}
                        >
                          ثبت نام کریر
                        </span>
                        <br />
                        <span style={{ verticalAlign: "middle", fontSize: 13 }}>
                          لطفاً اطلاعات خود را وارد کنید
                        </span>
                      </div>
                      <div className="formItem">
                        <span
                          style={{
                            verticalAlign: "middle",
                            fontSize: 14,
                            marginBottom: 2,
                            marginTop: 5,
                          }}
                        >
                          نام
                        </span>
                        <TextValidator
                          type="text"
                          classes="textInputFa"
                          ref={this.name}
                          name="name"
                          id="name"
                          value={this.state.form.name}
                          handleChange={this.handleChange}
                          validators={["required", "maxStringLength:20"]}
                          errorMessages={[
                            "نام را وارد کنید",
                            "نام حداکثر باید 20 کاراکتر باشد",
                          ]}
                        />
                      </div>
                      <div style={{ width: "100%", height: 5 }} />
                      <div className="formItem">
                        <span
                          style={{
                            verticalAlign: "middle",
                            fontSize: 14,
                            marginBottom: 2,
                            marginTop: 5,
                          }}
                        >
                          نام خانوادگی
                        </span>
                        <TextValidator
                          type="text"
                          classes="textInputFa"
                          ref={this.family}
                          name="family"
                          id="family"
                          value={this.state.form.family}
                          handleChange={this.handleChange}
                          validators={["required", "maxStringLength:30"]}
                          errorMessages={[
                            "نام خانوادگی را وارد کنید",
                            "نام خانوادگی حداکثر باید 30 کاراکتر باشد",
                          ]}
                        />
                      </div>
                      <div style={{ width: "100%", height: 5 }} />
                      <div className="formItem">
                        <span
                          style={{
                            verticalAlign: "middle",
                            fontSize: 14,
                            marginBottom: 2,
                            marginTop: 5,
                          }}
                        >
                          شماره موبایل
                        </span>
                        <TextValidator
                          type="text"
                          classes="textInputEn"
                          ref={this.mobile}
                          name="mobile"
                          id="mobile"
                          value={this.state.form.mobile}
                          handleChange={this.handleChange}
                          validators={[
                            "required",
                            "isMobileNumber",
                            "maxStringLength:11",
                          ]}
                          errorMessages={[
                            "شماره موبایل را وارد کنید",
                            " شماره موبایل نا معتبر می باشد",
                            "شماره همراه حداکثر باید 11 رقم باشد",
                          ]}
                        />
                      </div>
                      <div style={{ width: "100%", height: 5 }} />
                      <div className="formItem">
                        <span
                          style={{
                            verticalAlign: "middle",
                            fontSize: 14,
                            marginBottom: 2,
                            marginTop: 5,
                          }}
                        >
                          ایمیل
                        </span>
                        <TextValidator
                          type="text"
                          classes="textInputEn"
                          ref={this.emailRef}
                          name="email"
                          id="email"
                          placeholder="sample@gmail.com"
                          value={this.state.form.email}
                          handleChange={this.handleChange}
                          validators={["required", "isEmailAddress"]}
                          errorMessages={[
                            "ایمیل را وارد کنید",
                            " آدرس ایمیل نا معتبر می باشد",
                          ]}
                        />
                      </div>
                      <div style={{ width: "100%", height: 5 }} />
                      <div className="formItem">
                        <span
                          style={{
                            verticalAlign: "middle",
                            fontSize: 14,
                            marginBottom: 2,
                          }}
                        >
                          تعداد اکانت درخواستی فروش (حداکثر 6 نفر)
                        </span>
                        <TextValidator
                          type="text"
                          classes="textInputEn"
                          ref={this.count}
                          name="count"
                          id="count"
                          value={this.state.form.count}
                          handleChange={this.handleChange}
                          validators={["required", "isNumber"]}
                          errorMessages={[
                            "تعداد اکانت را وارد کنید",
                            "فقط عدد وارد کنید",
                          ]}
                        />
                      </div>
                      <div style={{ width: "100%", height: 5 }} />
                      <div className="formItem">
                        <span style={{ verticalAlign: "middle", fontSize: 14 }}>
                          نوع ثبت نام
                        </span>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            paddingRight: 100,
                          }}
                        >
                          <div style={{ width: "auto", textAlign: "left" }}>
                            <label>
                              شرکتی
                              <input
                                type="radio"
                                name="typeLoad"
                                value="2"
                                onChange={() => {this.onRegTypeChange(2);this.setState({pathname:"/confirmCompany"})}}
                              />
                            </label>
                          </div>
                          <div
                            style={{
                              width: "auto",
                              marginLeft: "auto",
                            }}
                          >
                            <label>
                              حقیقی
                              <input
                                type="radio"
                                name="typeLoad"
                                defaultChecked
                                value="1"
                                onChange={() => {this.onRegTypeChange(1);this.setState({pathname:"/confirmPersonal"})}}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="column-body">
                      <div className="formItem" style={{ marginTop: 60 }}>
                        <span
                          style={{
                            verticalAlign: "middle",
                            fontSize: 14,
                            marginBottom: 2,
                            marginTop: 2,
                          }}
                        >
                          رمز عبور
                        </span>
                        <TextValidator
                          type="password"
                          classes="textInputEn"
                          ref={this.passwordRef}
                          name="password"
                          id="password"
                          placeholder="*****"
                          value={this.state.form.password}
                          handleChange={this.handleChange}
                          validators={["required"]}
                          errorMessages={["کلمه عبور را وارد کنید"]}
                        />
                      </div>
                      <div style={{ width: "100%", height: 5 }} />
                      <div className="formItem">
                        <span
                          style={{
                            verticalAlign: "middle",
                            fontSize: 14,
                            marginBottom: 2,
                            marginTop: 5,
                          }}
                        >
                          تکرار رمز عبور
                        </span>
                        <TextValidator
                          type="password"
                          classes="textInputEn"
                          ref={this.password2}
                          name="password2"
                          id="password2"
                          value={this.state.form.password2}
                          handleChange={this.handleChange}
                          validators={["required"]}
                          errorMessages={["تکرار کلمه عبور را وارد کنید"]}
                        />
                      </div>
                      <div style={{ width: "100%", height: 5 }} />
                      <div
                        style={{
                          padding: 4,
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#cc0000",
                          display: this.state.comparePswrd ? "block" : "none",
                        }}
                      >
                        کلمه عبور و تکرار آن یکسان نیست
                      </div>
                      <div style={{ marginTop: 20, marginBottom: 10 }}>
                        <label
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <span style={{ color: "#2B61CD", fontSize: "12px" }}>
                            شرایط عضویت را مطالعه و موافقت نمودم
                          </span>
                          <input
                            type="checkbox"
                            name="radioOk"
                            value="1"
                            style={{ marginLeft: 30, opacity: 0.6 }}
                            checked={this.state.terms}
                            onClick={() => this.setState({ termsOpen: true })}
                            onChange={(e) => this.onCheckBoxChanged(e)}
                          />
                        </label>
                      </div>
                      <TermsModal
                        visible={this.state.termsOpen}
                        onConfirm={() =>
                          this.setState({ terms: true, termsOpen: false })
                        }
                        onClose={() => this.setState({ termsOpen: false })}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <ColoredLine width="100%" color="#fefefe" />
                      </div>
                      <div style={{ marginBottom: 10 }}>
                        <span style={{ verticalAlign: "middle", fontSize: 14 }}>
                          مبلغ عضویت {this.state.membership} ریال
                        </span>
                      </div>
                      {this.state.resultMessage ? (
                        <div
                          style={{
                            padding: 10,
                            textAlign: "center",
                            color: "#aa0000",
                            fontWeight: "bold",
                          }}
                        >
                          {this.state.resultMessage}
                        </div>
                      ) : null}
                      <div style={{ width: "100%", height: 40 }} />
                      <div>
                        <Button
                          text= {this.state.membership === 0 ?"ادامه" :"پرداخت"}
                          type="submit"
                          classes={this.state.btnClassName}
                          disabled={this.state.btnStatus}
                          loading={this.state.showLoading}
                        />
                        <div style={{ width: 100, height: 10 }}></div>
                        <div>
                          <Button
                            text="بازگشت به صفحه ثبت نام"
                            type="submit"
                            classes="btnOutLineNormal"
                            onClickFn={this.goToSignup.bind(this)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </ValidatorForm>
        </div>
      </div>
    );
  }
}
