import React from "react";
import { Spinner } from "reactstrap";

class Button extends React.Component {
  render() {
    return (
      <button
        onClick={this.props.onClickFn}
        className={`${this.props.classes} base-btn button-with-spinner m-l-15 ${
          !!this.props.disabled ? "button-disabled" : ""
        }`}
        type={this.props.type}
        disabled={this.props.disabled}
        style={{
          // backgroundColor: this.props.backgroundColor,
          // color: this.props.color,
          width: this.props.width,
          ...this.props.styles,
        }}
      >
        {this.props.text}
        {this.props.loading ? (
          <span className="spinner-holder">
            <Spinner color="gray" />
          </span>
        ) : null}
      </button>
    );
  }
}
export default Button;
