import React, { Component } from "react";

export class Logout extends Component {
  static displayName = Logout.name;

  componentDidMount() {
    localStorage.clear();
    sessionStorage.clear();
    sessionStorage.setItem("isLoggedIn", false);
    sessionStorage.removeItem("isLoggedIn");
    this.props.history.push("/login");
  }

  render() {
    return <></>;
  }
}
