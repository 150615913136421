import React from "react";

class TextInput extends React.Component {
  render() {
    return (
      <input
        placeholder={this.props.placeholder ? this.props.placeholder : ""}
        autoComplete="off"
        autoComplete="chrome-off"
        type={this.props.type}
        className={this.props.classes}
        id={this.props.name}
        name={this.props.name}
        value={this.props.value}
        maxLength={this.props.length}
        style={{ paddingRight: this.props.paddingRight + "px" }}
        onChange={this.props.handleChange}
        autoComplete="new-password"
      />
    );
  }
}
export default TextInput;
