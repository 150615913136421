import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import Button from "../General/Button";
import "../../App.css";

export class About extends Component {
  static displayName = About.name;

  constructor(props) {
    super(props);
  }

  goToHome() {
    const { history } = this.props;
    history.push("/");
  }

  render() {
    return (
      <div className="outerCntnt">
        <div
          className="centerContent"
          style={{ width: 1000, textAlign: "right", paddingTop: 10 }}
        >
          <div style={{ float: "right", width: 550, transform: "scale(0.92)" }}>
            <Card className="cardStyle inner-pages-card">
              <CardBody>
                <h1>درباره ما</h1>
                <p>
                  ایده ی ولگا حدود 5 سال پیش با حس نیاز شدید بازار سنتی حمل و
                  نقل ایران به یک درگاه یکپارچه و شفاف در ذهن ایده پرداز این
                  مجموعه شکل گرفت . بیش از 3 سال زمان برد تا این ایده بارها و
                  بارها ارزیابی شده و در هر مرحله پخته تر با زوایای جدیدی بررسی
                  شود.
                </p>
                <p>
                  تاکید میکنیم که این افتخار را داریم تا ادعا کنیم پیشتاز اجرایی
                  این طرح هستیم از دیماه 1398 بررسی ، امکان سنجی و طراحی های
                  اولیه این پلتفرم با تشکیل تیم فنی و ایده پردازی آغاز و بعد از
                  حدود 18 ماه تلاش بی وقفه مراحل فنی و گرافیکی و برنامه ریزی
                  مارکتینگ و امکان سنجی به مرحله ی نهایی خود رسید امروز اینجاییم
                  تا با ارایه اولین ورژن از یک پلتفرم بی تو بی موثر ، ساده و کم
                  هزینه ، به شما کمک کنیم تا با بالاترین بازدهی ممکن از وقت ،
                  سرمایه و نیروی انسانی خود برداشت کنید.
                </p>
                <p>برای اعتماد شما به این درگاه سپاسگزاریم.</p>

                <Button
                  text="بازگشت"
                  type="submit"
                  classes="btnOutLineNormal"
                  onClickFn={this.goToHome.bind(this)}
                />
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}
