import React, { Component } from "react";
import Axios from "axios";
import API_URL from "../Api/ApiUrl";
import { Container, Row, Col, Card, CardBody, Input } from "reactstrap";
import ReactQuill from "react-quill";
import JCalendar from "reactjs-persian-calendar";
import moment from "jalali-moment";
import Button from "../General/Button";
import Icon from "../Icons/Icon";
import Modal from "react-modal";
import "../../App.css";

Modal.setAppElement("*");
const customStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(110, 110, 110, 0.30)",
  },
  content: {
    width: 300,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 10,
    borderRadius: 10,
  },
};

//const onEnter = (event, callback) => event.key === 'Enter' && callback();

export class AddOffer extends Component {
  static displayName = AddOffer.name;

  constructor(props) {
    super(props);

    this.state = {
      title: "",
      description: "",
      color: "#c1d7fc",
      icon: "icn1.png",
      dateType: 1,
      locale: "fa",
      todayDate: moment().locale("fa").format("YYYY/MM/DD"),
      regDate: moment().locale("fa").format("YYYY/MM/DD"),
      expireDate: moment().locale("fa").format("YYYY/MM/DD"),
      resultMessage: "",
      butnOption1: true,
      butnOption2: false,
      butnOption3: false,
      chckmrk1: true,
      chckmrk2: false,
      chckmrk3: false,
      chckmrk4: false,
      chckmrk5: false,
      chckmrk6: false,
      showTitleVerify: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.openDatePicker = this.openDatePicker.bind(this);

    this.onDescriptionChange = (editor) =>
      this.setState({ description: editor });
  }

  handleChange(event) {
    this.setState({ resultMessage: "" });
    this.setState({ showTitleVerify: false });
    this.setState({ [event.target.id]: event.target.value });
  }

  async handleSubmit() {
    if (
      this.state.title.trim() === "" ||
      this.state.title.trim().length === 0
    ) {
      this.setState({ showTitleVerify: true });
      return false;
    }

    const offer = {
      Id: 0,
      Title: this.state.title,
      Description: this.state.description,
      ProviderId: Number(localStorage.getItem("userId")),
      RegDate: this.state.regDate,
      ExpireDate: this.state.expireDate,
      Color: this.state.color,
      Icon: this.state.icon,
      Views: 0,
      Status: 0,
    };

    Axios.post(API_URL + "Offer", offer)
      .then((res) => {
        var msg = res.data.message;
        if (msg === "ok") {
          this.setState({ title: "" });
          this.setState({ description: "" });
          this.setButnOptions(1);
          this.handleSelectColor(1);
          this.setState({ resultMessage: "پیشنهاد با موفقیت ثبت شد" });
        } else {
          this.setState({ resultMessage: "خطا در ثبت اطلاعات" });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  goToPanel() {
    var role_id = localStorage.getItem("roleId");
    switch (role_id) {
      case "2":
        this.props.history.push("/merchant");
        break;
      case "3":
        this.props.history.push("/carrier");
        break;
      case "4":
        this.props.history.push("/forwarder");
        break;
      case "5":
      case "6":
        this.props.history.push("/merchant");
        break;
    }
  }

  handleSelectColor(value) {
    var strColor = "#c1d7fc";
    switch (value) {
      case 1:
        this.setState({ chckmrk1: true });
        this.setState({ chckmrk2: false });
        this.setState({ chckmrk3: false });
        this.setState({ chckmrk4: false });
        this.setState({ chckmrk5: false });
        this.setState({ chckmrk6: false });
        strColor = "#c4d6ef";
        break;
      case 2:
        this.setState({ chckmrk1: false });
        this.setState({ chckmrk2: true });
        this.setState({ chckmrk3: false });
        this.setState({ chckmrk4: false });
        this.setState({ chckmrk5: false });
        this.setState({ chckmrk6: false });
        strColor = "#c0e7f8";
        break;
      case 3:
        this.setState({ chckmrk1: false });
        this.setState({ chckmrk2: false });
        this.setState({ chckmrk3: true });
        this.setState({ chckmrk4: false });
        this.setState({ chckmrk5: false });
        this.setState({ chckmrk6: false });
        strColor = "#e4e5e4";
        break;
      case 4:
        this.setState({ chckmrk1: false });
        this.setState({ chckmrk2: false });
        this.setState({ chckmrk3: false });
        this.setState({ chckmrk4: true });
        this.setState({ chckmrk5: false });
        this.setState({ chckmrk6: false });
        strColor = "#dcdad9";
        break;
      case 5:
        this.setState({ chckmrk1: false });
        this.setState({ chckmrk2: false });
        this.setState({ chckmrk3: false });
        this.setState({ chckmrk4: false });
        this.setState({ chckmrk5: true });
        this.setState({ chckmrk6: false });
        strColor = "#b0c8e9";
        break;
      case 6:
        this.setState({ chckmrk1: false });
        this.setState({ chckmrk2: false });
        this.setState({ chckmrk3: false });
        this.setState({ chckmrk4: false });
        this.setState({ chckmrk5: false });
        this.setState({ chckmrk6: true });
        strColor = "#a5daf4";
        break;
    }

    this.setState({ color: strColor });
  }

  setButnOptions = (value) => {
    this.setState({ reqStatus: value });
    if (value === 1) {
      this.setState({ butnOption1: true });
      this.setState({ butnOption2: false });
      this.setState({ butnOption3: false });
      this.setState({ icon: "icn1.png" });
    }
    if (value === 2) {
      this.setState({ butnOption1: false });
      this.setState({ butnOption2: true });
      this.setState({ butnOption3: false });
      this.setState({ icon: "icn2.png" });
    }
    if (value === 3) {
      this.setState({ butnOption1: false });
      this.setState({ butnOption2: false });
      this.setState({ butnOption3: true });
      this.setState({ icon: "icn3.png" });
    }
  };

  openDatePicker(type) {
    this.setState({ dateModalVisible: true });
    this.setState({ dateType: type });
  }

  closeModal() {
    this.setState({ dateModalVisible: false });
  }

  handleModalResponse() {
    const { dateType } = this.state;
    var strDate = this.state.selectedDate.replace("-", "/");
    strDate = strDate.replace("-", "/");
    if (dateType == 1) {
      if (strDate > this.state.todayDate) {
        this.setState({ regDate: strDate });
      }
      if (strDate > this.state.expireDate)
        this.setState({ expireDate: strDate });
    }
    if (dateType == 2) {
      if (strDate > this.state.todayDate && strDate > this.state.regDate) {
        this.setState({ expireDate: strDate });
      }
    }
    this.setState({ dateModalVisible: false });
    this.setState({ showWarning: false });
  }

  render() {
    const { locale, regDate, expireDate } = this.state;
    return (
      <div className="outerCntnt">
        <div
          className="centerContent"
          style={{ width: 1000, textAlign: "right", paddingTop: 10 }}
        >
          <div style={{ float: "right", width: "100%" }}>
            <div style={{ marginBottom: 20, marginRight: 20 }}>
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#2F306E",
                }}
              >
                تعریف پیشنهاد جدید
              </span>
            </div>
            <Card
              className="cardStyle"
              style={{ height: "auto", padding: "20px 40px 40px" }}
            >
              <CardBody>
                <div
                  style={{
                    width: "100%",
                    height: "auto",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      width: "45%",
                      marginLeft: -8,
                    }}
                  >
                    <Container>
                      <Row>
                        <Col sm="12" style={{ textAlign: "right", padding: 5 }}>
                          <span className="labeled">دسته بندی</span>
                          <div style={{ display: "flex" }}>
                            <div
                              className={
                                this.state.butnOption1
                                  ? "btnSlctOn"
                                  : "btnSlctOff"
                              }
                              onClick={() => {
                                this.setButnOptions(1);
                              }}
                            >
                              <img
                                src={
                                  this.state.butnOption1
                                    ? require("../../assets/images/transit2.png")
                                    : require("../../assets/images/transit1.png")
                                }
                                alt=""
                                height="40"
                                style={{ marginBottom: 20 }}
                              />
                              <div>معرفی سرویس</div>
                            </div>

                            <div
                              className={
                                this.state.butnOption2
                                  ? "btnSlctOn"
                                  : "btnSlctOff"
                              }
                              onClick={() => {
                                this.setButnOptions(2);
                              }}
                            >
                              <img
                                src={
                                  this.state.butnOption2
                                    ? require("../../assets/images/export2.png")
                                    : require("../../assets/images/export1.png")
                                }
                                alt=""
                                height="40"
                                style={{ marginBottom: 20 }}
                              />
                              <div>صدور بارنامه</div>
                            </div>

                            <div
                              className={
                                this.state.butnOption3
                                  ? "btnSlctOn"
                                  : "btnSlctOff"
                              }
                              onClick={() => {
                                this.setButnOptions(3);
                              }}
                            >
                              <img
                                src={
                                  this.state.butnOption3
                                    ? require("../../assets/images/release2.png")
                                    : require("../../assets/images/release1.png")
                                }
                                alt=""
                                height="40"
                                style={{ marginBottom: 20 }}
                              />
                              <div>ترخیص کالا</div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ marginBottom: -40, marginTop: 40 }}>
                        <Col sm="12" style={{ textAlign: "right", padding: 5 }}>
                          <span className="labeled">مدت اعتبار</span>
                        </Col>
                      </Row>
                      <Row style={{ marginBottom: 20 }}>
                        <Col sm="12" style={{ textAlign: "right", padding: 5 }}>
                          <Container>
                            <Row>
                              <Col
                                sm="4"
                                style={{
                                  textAlign: "left",
                                  direction: "rtl",
                                  padding: 5,
                                }}
                              >
                                <img
                                  src={require("../../assets/images/notegray.png")}
                                  width="100"
                                  alt=""
                                  style={{ marginBottom: 0 }}
                                />
                              </Col>
                              <Col
                                sm="8"
                                style={{
                                  width: "120px",
                                  textAlign: "right",
                                  padding: 8,
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  flexDirection: "column",
                                }}
                              >
                                <Row>
                                  <Col
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <div
                                      className="dateButton"
                                      onClick={() => this.openDatePicker(1)}
                                      style={{ marginRight: 40 }}
                                    >
                                      {this.state.regDate}
                                    </div>
                                    <span style={{ direction: "rtl" }}>
                                      از تاریخ:
                                    </span>
                                  </Col>
                                </Row>
                                <Row style={{ marginTop: 10 }}>
                                  <Col
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <div
                                      className="dateButton"
                                      onClick={() => this.openDatePicker(2)}
                                      style={{ marginRight: 40 }}
                                    >
                                      {this.state.expireDate}
                                    </div>
                                    <span style={{ direction: "rtl" }}>
                                      تا تاریخ:
                                    </span>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Container>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          sm="12"
                          style={{
                            textAlign: "center",
                            padding: 5,
                            marginTop: 30,
                            paddingBottom: 0,
                          }}
                        >
                          <Button
                            text="تأیید"
                            type="submit"
                            classes="btnOrangeBox"
                            width="150px"
                            styles={{ marginBottom: "3px" }}
                            onClickFn={this.handleSubmit}
                          />
                          <div style={{ width: 100, height: 1 }}></div>
                          <Button
                            text="بازگشت"
                            type="submit"
                            classes="btnOutlineBlueBox"
                            width="150px"
                            styles={{ marginTop: "3px", marginBottom: 0 }}
                            onClickFn={this.goToPanel.bind(this)}
                          />
                          {this.state.resultMessage ? (
                            <div style={{ height: "20px", fontWeight: "bold" }}>
                              {this.state.resultMessage}
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                    </Container>
                  </div>
                  <div style={{ width: "10%" }}></div>
                  <div
                    style={{
                      width: "45%",
                      display: "flex",
                      flexDirection: "column",
                      border: "0px solid #007700",
                    }}
                  >
                    <span className="labeled">عنوان پیشنهاد</span>
                    <Input
                      className="custom-border"
                      name="title"
                      id="title"
                      type="text"
                      value={this.state.title}
                      onChange={this.handleChange}
                      style={{ direction: "rtl", height: 40 }}
                    />
                    <div
                      style={{
                        marginTop: 5,
                        display: this.state.showTitleVerify ? "inline" : "none",
                      }}
                    >
                      <span style={{ color: "#ee0000", fontSize: "12px" }}>
                        عنوان وارد نشده است
                      </span>
                    </div>
                    <br />
                    <span className="labeled">توضیحات</span>
                    <ReactQuill
                      theme="snow"
                      value={this.state.description}
                      onChange={this.onDescriptionChange}
                    />
                    <br />
                    <span className="labeled">انتخاب رنگ پس زمینه</span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "auto",
                        justifyContent: "space-between",
                        paddingTop: 20,
                        paddingRight: 50,
                        paddingBottom: 20,
                        paddingLeft: 50,
                        border: "1px solid #C8C9CB",
                        borderRadius: 5,
                      }}
                    >
                      <div
                        onClick={() => this.handleSelectColor(1)}
                        style={{
                          width: 40,
                          height: 40,
                          backgroundColor: "#c4d6ef",
                          padding: 5,
                          cursor: "pointer",
                          borderRadius: 5,
                          textAlign: "center",
                          paddingTop: 10,
                        }}
                      >
                        <Icon
                          icon="check"
                          size={20}
                          color="#1178aa"
                          style={{
                            display: this.state.chckmrk1 ? "inline" : "none",
                          }}
                        />
                      </div>
                      <div
                        onClick={() => this.handleSelectColor(2)}
                        style={{
                          width: 40,
                          height: 40,
                          backgroundColor: "#c0e7f8",
                          padding: 5,
                          cursor: "pointer",
                          borderRadius: 5,
                          textAlign: "center",
                          paddingTop: 10,
                        }}
                      >
                        <Icon
                          icon="check"
                          size={20}
                          color="#1178aa"
                          style={{
                            display: this.state.chckmrk2 ? "inline" : "none",
                          }}
                        />
                      </div>
                      <div
                        onClick={() => this.handleSelectColor(3)}
                        style={{
                          width: 40,
                          height: 40,
                          backgroundColor: "#e4e5e4",
                          padding: 5,
                          cursor: "pointer",
                          borderRadius: 5,
                          textAlign: "center",
                          paddingTop: 10,
                        }}
                      >
                        <Icon
                          icon="check"
                          size={20}
                          color="#1178aa"
                          style={{
                            display: this.state.chckmrk3 ? "inline" : "none",
                          }}
                        />
                      </div>
                      <div
                        onClick={() => this.handleSelectColor(4)}
                        style={{
                          width: 40,
                          height: 40,
                          backgroundColor: "#dcdad9",
                          padding: 5,
                          cursor: "pointer",
                          borderRadius: 5,
                          textAlign: "center",
                          paddingTop: 10,
                        }}
                      >
                        <Icon
                          icon="check"
                          size={20}
                          color="#1178aa"
                          style={{
                            display: this.state.chckmrk4 ? "inline" : "none",
                          }}
                        />
                      </div>
                      <div
                        onClick={() => this.handleSelectColor(5)}
                        style={{
                          width: 40,
                          height: 40,
                          backgroundColor: "#b0c8e9",
                          padding: 5,
                          cursor: "pointer",
                          borderRadius: 5,
                          textAlign: "center",
                          paddingTop: 10,
                        }}
                      >
                        <Icon
                          icon="check"
                          size={20}
                          color="#1178aa"
                          style={{
                            display: this.state.chckmrk5 ? "inline" : "none",
                          }}
                        />
                      </div>
                      <div
                        onClick={() => this.handleSelectColor(6)}
                        style={{
                          width: 40,
                          height: 40,
                          backgroundColor: "#a5daf4",
                          padding: 5,
                          cursor: "pointer",
                          borderRadius: 5,
                          textAlign: "center",
                          paddingTop: 10,
                        }}
                      >
                        <Icon
                          icon="check"
                          size={20}
                          color="#1178aa"
                          style={{
                            display: this.state.chckmrk6 ? "inline" : "none",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>

        {/* DatePicker Modal */}
        <Modal isOpen={this.state.dateModalVisible} style={customStyles}>
          <Container>
            <Row>
              <Col
                style={{
                  alignSelf: "center",
                  textAlign: "center",
                  direction: "rtl",
                }}
              >
                <JCalendar
                  key={locale}
                  locale={locale}
                  color={"#FFB509"}
                  size={30}
                  onClick={(fa) => this.setState({ selectedDate: fa })}
                  itemRender={(key, item, children) => children}
                />
              </Col>
            </Row>
            <Row style={{ paddingTop: 20 }}>
              <Col xs="6" style={{ textAlign: "center" }}>
                <Button
                  text="انصراف"
                  type="submit"
                  classes="btnOutlineBlueBox"
                  width="70px"
                  onClickFn={this.closeModal.bind(this)}
                />
              </Col>
              <Col xs="6" style={{ textAlign: "center" }}>
                <Button
                  text="تأیید"
                  type="submit"
                  classes="btnOrangeBox"
                  width="70px"
                  onClickFn={this.handleModalResponse.bind(this)}
                />
              </Col>
            </Row>
          </Container>
        </Modal>
      </div>
    );
  }
}
