import React, { Component, Suspense } from "react";
import { Container, Spinner } from "reactstrap";
import { Redirect } from "react-router-dom";

export class AuthLayout extends Component {
  static displayName = AuthLayout.name;

  loading = () => (
    <div className="animated fadeIn pt-1 text-center">
      <Spinner style={{ width: "2rem", height: "2rem" }} color="danger" />
    </div>
  );

  // goToStartPage() {
  //   //   const { history } = this.props;
  //   //   history.push('/');

  //   //   //onClick={this.goToStartPage.bind(this)}

  //   // this.context.router.transitionTo('/');
  // }

  handleClick = () => {
    return <Redirect to="/" push={true} />;
  };

  render() {
    return (
      <div>
        <div className="mainBg">
          <img
            src={require("../../assets/images/earthmap@2x.png")}
            width="80%"
            alt=""
          />
          <div className="mainContent">
            <Container>{this.props.children}</Container>
          </div>
          <div className="divLogo">
            <a href="/" onClick={this.handleClick}>
              <img
                src={require("../../assets/images/volgalogo120.png")}
                className="imgLogo"
                width="120"
                alt=""
                style={{ cursor: "pointer" }}
              />
            </a>
          </div>
        </div>
      </div>
    );
  }
}
