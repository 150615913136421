import React, { Component } from "react";
import Axios from "axios";
import API_URL from "../Api/ApiUrl";
import { Card, CardBody } from "reactstrap";
import { ValidatorForm } from "react-form-validator-core";
import TextValidator from "../General/TextValidator";
import { isMobileNumber } from "../Utils/Utility";
import moment from "jalali-moment";
import Button from "../General/Button";
import "../../App.css";

export class Account extends Component {
  static displayName = Account.name;

  form;
  name;
  family;
  mobile;
  constructor(props) {
    super(props);

    this.state = {
      form: {
        name: "",
        family: "",
        mobile: "",
      },
      isSaved: false,
      btnStatus: "",
      btnClassName: "btnBlueNormal",
      resultMessage: "",
      showLoading: false,
    };

    this.form = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    ValidatorForm.addValidationRule("isMobileNumber", (value) =>
      isMobileNumber(value)
    );
  }

  handleChange(event) {
    this.setState({ resultMessage: "" });
    this.setState({
      form: {
        ...this.state.form,
        [event.target.id]: event.target.value,
      },
    });
  }

  async handleSubmit() {
    this.setState({ showLoading: true });
    const mobile = this.state.form.mobile;
    Axios.get(API_URL + "Account/checkAccount/" + mobile)
      .then((res) => {
        var result = res.data.result;
        if (result != 0) {
          this.setState({ showLoading: false });
          this.setState({
            resultMessage: "اکانتی با این شماره قبلاً ثبت شده است",
          });
          return;
        } else {
          this.goToVerify();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async goToVerify() {
    this.setState({ showLoading: true });
    const account = {
      Id: 0,
      Name: this.state.form.name,
      Family: this.state.form.family,
      Mobile: this.state.form.mobile,
      Email: "",
      RegDate: moment().locale("fa").format("YYYY/MM/DD"),
      Access: 1,
    };

    const { history } = this.props;
    history.push({
      pathname: "/codeVerify",
      state: {
        actionType: "acount",
        mobileNumber: this.state.form.mobile,
        objectMdl: account,
      },
    });
  }

  goToStart() {
    const { history } = this.props;
    history.push("/");
  }

  render() {
    return (
      <div className="outerCntnt">
        <div className="innerCntnt">
          <div style={{ width: "100%", textAlign: "right", paddingTop: 25 }}>
            <div style={{ float: "right", width: 450, paddingLeft: 15 }}>
              <Card className="cardStyle auth-card">
                <CardBody>
                  <div style={{ marginBottom: 20 }}>
                    <span
                      style={{
                        verticalAlign: "middle",
                        fontSize: 13,
                        fontWeight: "bold",
                      }}
                    >
                      اکانت یک روزه رایگان
                    </span>
                    <div style={{ width: 100, height: 30 }}></div>
                    <span style={{ verticalAlign: "middle", fontSize: 14 }}>
                      مشخصات خود را وارد کنید
                    </span>
                  </div>
                  <div className="welButn">
                    <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
                      <div style={{ marginBottom: 5 }}>
                        <span style={{ verticalAlign: "middle", fontSize: 14 }}>
                          نام
                        </span>
                      </div>
                      <TextValidator
                        type="text"
                        classes="textInputFa"
                        ref={this.name}
                        name="name"
                        id="name"
                        value={this.state.form.name}
                        handleChange={this.handleChange}
                        validators={["required", "maxStringLength:20"]}
                        errorMessages={[
                          "نام را وارد کنید",
                          "نام حداکثر باید 20 کاراکتر باشد",
                        ]}
                      />
                      <br />
                      <div style={{ marginBottom: 5 }}>
                        <span style={{ verticalAlign: "middle", fontSize: 14 }}>
                          نام خانوادگی
                        </span>
                      </div>
                      <TextValidator
                        type="text"
                        classes="textInputFa"
                        ref={this.family}
                        name="family"
                        id="family"
                        value={this.state.form.family}
                        handleChange={this.handleChange}
                        validators={["required", "maxStringLength:30"]}
                        errorMessages={[
                          "نام خانوادگی را وارد کنید",
                          "نام خانوادگی حداکثر باید 30 کاراکتر باشد",
                        ]}
                      />
                      <br />
                      <div style={{ marginBottom: 5 }}>
                        <span style={{ verticalAlign: "middle", fontSize: 14 }}>
                          شماره موبایل
                        </span>
                      </div>
                      <TextValidator
                        type="text"
                        classes="textInputEn"
                        ref={this.mobile}
                        name="mobile"
                        id="mobile"
                        value={this.state.form.mobile}
                        handleChange={this.handleChange}
                        validators={[
                          "required",
                          "isMobileNumber",
                          "maxStringLength:11",
                        ]}
                        errorMessages={[
                          "شماره موبایل را وارد کنید",
                          " شماره موبایل نا معتبر می باشد",
                          "شماره همراه حداکثر باید 11 رقم باشد",
                        ]}
                      />
                      <div
                        style={{
                          height: "auto",
                          padding: 12,
                          textAlign: "center",
                        }}
                      >
                        <span
                          style={{
                            verticalAlign: "middle",
                            fontSize: 14,
                            fontWeight: "bold",
                            color: "#008800",
                          }}
                        >
                          {this.state.resultMessage}
                        </span>
                        <br />
                      </div>
                      <div className="m-t-30 text-center">
                        <Button
                          text="ثبت"
                          type="submit"
                          classes={this.state.btnClassName}
                          disabled={this.state.btnStatus}
                          loading={this.state.showLoading}
                        />
                      </div>
                      <div style={{ width: 100, height: 10 }}></div>
                      <div className="m-t-30 text-center">
                        <Button
                          text="بازگشت"
                          type="submit"
                          classes="btnOutLineNormal"
                          onClickFn={this.goToStart.bind(this)}
                        />
                      </div>
                    </ValidatorForm>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
