import React, { Component, Suspense } from "react";
import { Container, Spinner } from "reactstrap";
import Axios from "axios";
import API_URL from "../Api/ApiUrl";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "@coreui/coreui/dist/css/coreui.min.css";
import Icon from "../Icons/Icon";
import { UserAvatar } from "./UserAvatar";
// import defProfile from '../../assets/images/profiledefault.png';

export class UserLayout extends Component {
  static displayName = UserLayout.name;

  constructor(props) {
    super(props);
    this.role_id = Number(localStorage.getItem("roleId"));
    this.state = {
      avatarSource: null,
      avatarBase64: null,
      imgSrc: null,
      selectedFile: null,
    };
  }

  componentDidMount() {
    Axios.get(
      API_URL + "Avatar/getUserAvatar/" + localStorage.getItem("userCode")
    )
      .then((response) => {
        if (response.data.id !== 0) {
          var avtrBase64 = response.data.image;
          this.setState({ avatarBase64: avtrBase64 });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  loading = () => (
    <div className="animated fadeIn pt-1 text-center">
      <Spinner style={{ width: "2rem", height: "2rem" }} color="danger" />
    </div>
  );

  goLogout() {
    const { history } = this.props;
    history.push("/logout");
  }

  render() {
    const roleTitle = localStorage.getItem("roleTitle");
    const nameFamily = localStorage.getItem("nameFamily");
    const userLogo = {
      Seller: require("../../assets/images/merchant-gray.png"),
      Carrier: require("../../assets/images/carrier-gray.png"),
      Forwarder: require("../../assets/images/forwarder-gray.png"),
    };

    return (
      <div>
        <div className="userHeader" style={{ height: 80 }}>
          <Navbar
            expand="lg"
            sticky="top"
            style={{
              height: 80,
              backgroundColor: "white",
              boxShadow: "0 0 .5rem rgba(0, 0, 0, .05)",
              paddingLeft: 100,
              paddingRight: 50,
            }}
          >
            <Navbar.Brand>
              <img
                src={require("../../assets/images/volgalogo.png")}
                height="60"
                alt=""
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="justify-content-end"
            >
              {this.role_id === 6 ? (
                <Nav.Link href="/logout">
                  <Icon icon={"power-off"} size={20} color="gray" />
                </Nav.Link>
              ) : null}

              {this.role_id != 6 ? (
                <>
                  <Nav.Link href="/messages">
                    <Icon icon={"notifications"} size={24} color="gray" />
                  </Nav.Link>

                  <NavDropdown
                    title={nameFamily}
                    id="collasible-nav-dropdown"
                    className="profile-dropdown"
                  >
                    <NavDropdown.Item href="/profile">پروفایل</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/logout">خـروج</NavDropdown.Item>
                  </NavDropdown>
                </>
              ) : null}

              <Navbar.Brand style={{ paddingRight: 0, margin: 0 }}>
                <UserAvatar avatarBase64={this.state.avatarBase64} />

                {/* {this.state.avatarBase64 ? <img src={`${this.state.avatarBase64}`} className="userAvatar" alt="" /> :
                  <img src={defProfile} className="userAvatar" alt="" />} */}
              </Navbar.Brand>

              {userLogo[roleTitle] ? (
                <>
                  <span className="header-brand-divider" />
                  <Navbar.Brand>
                    <img src={userLogo[roleTitle]} width="40" alt="" />
                  </Navbar.Brand>
                </>
              ) : null}
            </Navbar.Collapse>
          </Navbar>
        </div>
        <div className="userBody">
          <img
            src={require("../../assets/images/earthmap@2x.png")}
            width="80%"
            alt=""
          />
          <div className="userContent">{this.props.children}</div>
        </div>
      </div>
    );
  }
}
