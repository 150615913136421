import React, { Component } from "react";
import Axios from "axios";
import API_URL from "../Api/ApiUrl";
import { Card, CardBody } from "reactstrap";
import { ValidatorForm } from "react-form-validator-core";
import TextValidator from "../General/TextValidator";
import { isEmailAddress, isMobileNumber } from "../Utils/Utility";
import moment from "jalali-moment";
import Button from "../General/Button";
import TermsModal from "../Terms/TermsModal";
import "../../App.css";

export class SignupMerchant extends Component {
  static displayName = SignupMerchant.name;

  form;
  name;
  family;
  company;
  mobile;
  email;
  activity;
  volume;
  password;
  constructor(props) {
    super(props);

    this.state = {
      form: {
        name: "",
        family: "",
        company: "",
        mobile: "",
        email: "",
        activity: "",
        volume: "",
        password: "",
        password2: "",
      },
      termsOpen: false,
      terms: false,
      btnStatus: "disabled",
      btnClassName: "btnBlueDisabled",
      membership: 0,
      typeLoad: "1",
      resultMessage: "",
      showLoading: false,
      comparePswrd: false,regType: 2,
    };

    this.form = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    ValidatorForm.addValidationRule("isMobileNumber", (value) =>
      isMobileNumber(value)
    );
    ValidatorForm.addValidationRule("isEmailAddress", (value) =>
      isEmailAddress(value)
    );

    Axios.get(API_URL + "User/getItemAmount/merchant")
      .then((response) => {
        this.setState({ membership: response.data.itemValue });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleChange(event) {
    this.setState({ comparePswrd: false });
    this.setState({ resultMessage: "" });
    this.setState({
      form: {
        ...this.state.form,
        [event.target.id]: event.target.value,
      },
    });
  }

  async handleSubmit() {
    var pswrd1 = this.state.form.password;
    var pswrd2 = this.state.form.password2;

    if (pswrd1 != pswrd2) {
      this.setState({ comparePswrd: true });
      return;
    }

    this.setState({ showLoading: true });

    const merchant = {
      Id: 0,
      Name: this.state.form.name,
      Family: this.state.form.family,
      Mobile: this.state.form.mobile,
      Email: this.state.form.email,
      NationalNo: "",
      IdentityNo: "",
      Company: this.state.form.company,
      Activity: this.state.form.activity,
      PayLoadType: 1,
      Volume: 0,
      RegDate: moment().locale("fa").format("YYYY/MM/DD"),
      Status: 0,
      password: this.state.form.password,
    };

    Axios.post(API_URL + "Merchant", merchant)
      .then((res) => {
        const message = res.data.message;
        if (message === "ok") {
          const authority = res.data.authority;
          const payId = res.data.payId;
          const userId = res.data.userId;
          const merchantId = res.data.id;

          localStorage.clear();
          setTimeout(() => {
            localStorage.setItem("payId", payId);
            localStorage.setItem("userId", userId);
            localStorage.setItem("merchantId", merchantId);
            localStorage.setItem("utype", "merchant");

            // window.location = `https://www.zarinpal.com/pg/StartPay/${authority}`;
            
            const utype = localStorage.getItem("utype");
            const signupType =
            utype === "merchant" ? 1 : utype === "provider" ? 2 : null;
            const pathname =  this.state.form.company === "" ? "/confirmPersonal":"/confirmCompany" ;
            // console.log(pathname)
            
            // console.log(merchantId)
            this.props.history.push({
              pathname: pathname,
              merchantId,signupType
            });
            // this.props.location.merchantId
            // this.props.history.push({
            //   pathname,
            //   signupType,
            //   merchantId,
            //   // action: Number(sellerCount) > 0 ? "add" : "none",
            // });
          });
        } else if (message === "exist") {
          this.setState({
            resultMessage: "کاربری با این ایمیل قبلاً ثبت شده است",
          });
        }
        this.setState({ showLoading: false });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onTypeLoadChange = (value) => {
    this.setState({ typeLoad: value });
  };

  onCheckBoxChanged(e) {
    let isChecked = e.target.checked;
    if (isChecked) {
      this.setState({ btnStatus: "" });
      this.setState({ btnClassName: "btnBlueNormal" });
    } else {
      this.setState({ btnStatus: "disabled" });
      this.setState({ btnClassName: "btnBlueDisabled" });
    }
  }

  goToSignup() {
    const { history } = this.props;
    history.push("/signup");
  }

  render() {
    const ColoredLine = ({ color, width }) => (
      <hr
        style={{
          color: color,
          backgroundColor: color,
          width: width,
          height: 1,
        }}
      />
    );
    return (
      <div
        style={{
          width: "auto",
          float: "right",
          display: "flex",
          justifyContent: "flex-end",
          alignSelf: "flex-end",
          alignItems: "flex-end",
          border: "0px solid #00ee00",
          marginRight: -50,
        }}
      >
        <div style={{ margin: 100 }}>
          <img
            src={require("../../assets/images/signupWhiteIcon1.png")}
            width="220"
            alt=""
          />
        </div>
        <div
          style={{
            width: 930,
            transform: "scale(0.85)",
            textAlign: "right",
            paddingTop: 25,
          }}
        >
          <ValidatorForm onSubmit={this.handleSubmit}>
            <Card className="cardStyle dark-shadow signup-big-card">
              <CardBody>
                <div className="row signup-columns">
                  <div
                    className="col-6"
                    style={{ borderLeft: "1px solid #E6E5E5" }}
                  >
                    <div className="column-body">
                      <div style={{ marginBottom: 10 }}>
                        <span
                          style={{
                            verticalAlign: "middle",
                            fontSize: 15,
                            fontWeight: "bold",
                          }}
                        >
                          ثبت نام بازرگان
                        </span>
                        <br />
                        <span style={{ verticalAlign: "middle", fontSize: 13 }}>
                          لطفاً اطلاعات خود را وارد کنید
                        </span>
                      </div>
                      <div className="formItem">
                        <span
                          style={{
                            verticalAlign: "middle",
                            fontSize: 14,
                            marginBottom: 2,
                            marginTop: 5,
                          }}
                        >
                          نام
                        </span>
                        <TextValidator
                          type="text"
                          classes="textInputFa"
                          ref={this.name}
                          name="name"
                          id="name"
                          value={this.state.form.name}
                          handleChange={this.handleChange}
                          validators={["required", "maxStringLength:20"]}
                          errorMessages={[
                            "نام را وارد کنید",
                            "نام حداکثر باید 20 کاراکتر باشد",
                          ]}
                        />
                      </div>
                      <div style={{ width: "100%", height: 5 }} />
                      <div className="formItem">
                        <span
                          style={{
                            verticalAlign: "middle",
                            fontSize: 14,
                            marginBottom: 2,
                            marginTop: 5,
                          }}
                        >
                          نام خانوادگی
                        </span>
                        <TextValidator
                          type="text"
                          classes="textInputFa"
                          ref={this.family}
                          name="family"
                          id="family"
                          value={this.state.form.family}
                          handleChange={this.handleChange}
                          validators={["required", "maxStringLength:30"]}
                          errorMessages={[
                            "نام خانوادگی را وارد کنید",
                            "نام خانوادگی حداکثر باید 30 کاراکتر باشد",
                          ]}
                        />
                      </div>
                      <div style={{ width: "100%", height: 5 }} />
                      <div className="formItem">
                        <span
                          style={{
                            verticalAlign: "middle",
                            fontSize: 14,
                            marginBottom: 2,
                            marginTop: 5,
                          }}
                        >
                          نام شرکت
                        </span>
                        <TextValidator
                          type="text"
                          classes="textInputFa"
                          ref={this.company}
                          name="company"
                          id="company"
                          value={this.state.form.company}
                          handleChange={this.handleChange}
                          validators={["maxStringLength:40"]}
                          errorMessages={[
                            "نام شرکت حداکثر باید 40 کاراکتر باشد",
                          ]}
                        />
                      </div>
                      <div style={{ width: "100%", height: 5 }} />
                      <div className="formItem">
                        <span
                          style={{
                            verticalAlign: "middle",
                            fontSize: 14,
                            marginBottom: 2,
                            marginTop: 5,
                          }}
                        >
                          ایمیل
                        </span>
                        <TextValidator
                          type="text"
                          classes="textInputEn"
                          ref={this.emailRef}
                          name="email"
                          id="email"
                          placeholder="sample@gmail.com"
                          value={this.state.form.email}
                          handleChange={this.handleChange}
                          validators={["required", "isEmailAddress"]}
                          errorMessages={[
                            "ایمیل را وارد کنید",
                            " آدرس ایمیل نا معتبر می باشد",
                          ]}
                        />
                      </div>
                      <div style={{ width: "100%", height: 5 }} />
                      <div className="formItem">
                        <span
                          style={{
                            verticalAlign: "middle",
                            fontSize: 14,
                            marginBottom: 2,
                            marginTop: 5,
                          }}
                        >
                          زمینه فعالیت
                        </span>
                        <TextValidator
                          type="text"
                          classes="textInputFa"
                          ref={this.activity}
                          name="activity"
                          id="activity"
                          value={this.state.form.activity}
                          handleChange={this.handleChange}
                          validators={["required", "maxStringLength:40"]}
                          errorMessages={[
                            "زمینه فعالیت را وارد کنید",
                            "زمینه فعالیت حداکثر باید 40 کاراکتر باشد",
                          ]}
                        />
                      </div>
                      <div style={{ width: "100%", height: 5 }} />
                      <div className="formItem">
                        <span
                          style={{
                            verticalAlign: "middle",
                            fontSize: 14,
                            marginBottom: 2,
                            marginTop: 5,
                          }}
                        >
                          شماره موبایل
                        </span>
                        <TextValidator
                          type="text"
                          classes="textInputEn"
                          ref={this.mobile}
                          name="mobile"
                          id="mobile"
                          value={this.state.form.mobile}
                          handleChange={this.handleChange}
                          validators={[
                            "required",
                            "isMobileNumber",
                            "maxStringLength:11",
                          ]}
                          errorMessages={[
                            "شماره موبایل را وارد کنید",
                            " شماره موبایل نا معتبر می باشد",
                            "شماره همراه حداکثر باید 11 رقم باشد",
                          ]}
                        />
                      </div>
                      <div style={{ width: "100%", height: 5 }} />
                      <div className="formItem">
                        <span style={{ verticalAlign: "middle", fontSize: 14 }}>
                          نوع محموله
                        </span>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            paddingRight: 100,
                          }}
                        >
                          <div
                            style={{
                              width: "auto",
                            }}
                          >
                            <label>
                              واردات
                              <input
                                type="radio"
                                name="typeLoad"
                                value="2"
                                onChange={() => this.onTypeLoadChange("2")}
                              />
                            </label>
                          </div>
                          <div
                            style={{
                              width: "auto",
                              textAlign: "center",
                              marginLeft: "auto",
                            }}
                          >
                            <label>
                              صادرات
                              <input
                                type="radio"
                                name="typeLoad"
                                defaultChecked
                                value="1"
                                onChange={() => this.onTypeLoadChange("1")}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="column-body">
                      <div className="formItem" style={{ marginTop: 60 }}>
                        <span
                          style={{
                            verticalAlign: "middle",
                            fontSize: 14,
                            marginBottom: 2,
                          }}
                        >
                          حجم حدودی ماهیانه به تعداد کانتینر
                        </span>
                        <TextValidator
                          type="number"
                          classes="textInputEn"
                          ref={this.volume}
                          name="volume"
                          id="volume"
                          value={this.state.form.volume}
                          handleChange={this.handleChange}
                          validators={["required", "isNumber"]}
                          errorMessages={[
                            "تعداد کانتینر را وارد کنید",
                            "فقط عدد وارد کنید",
                          ]}
                        />
                      </div>
                      <div style={{ width: "100%", height: 5 }} />
                      <div className="formItem">
                        <span
                          style={{
                            verticalAlign: "middle",
                            fontSize: 14,
                            marginBottom: 2,
                            marginTop: 2,
                          }}
                        >
                          رمز عبور
                        </span>
                        <TextValidator
                          type="password"
                          classes="textInputEn"
                          ref={this.passwordRef}
                          name="password"
                          id="password"
                          placeholder="*****"
                          value={this.state.form.password}
                          handleChange={this.handleChange}
                          validators={["required"]}
                          errorMessages={["کلمه عبور را وارد کنید"]}
                        />
                      </div>
                      <div style={{ width: "100%", height: 5 }} />
                      <div className="formItem">
                        <span
                          style={{
                            verticalAlign: "middle",
                            fontSize: 14,
                            marginBottom: 2,
                            marginTop: 5,
                          }}
                        >
                          تکرار رمز عبور
                        </span>
                        <TextValidator
                          type="password"
                          classes="textInputEn"
                          ref={this.password2}
                          name="password2"
                          id="password2"
                          value={this.state.form.password2}
                          handleChange={this.handleChange}
                          validators={["required"]}
                          errorMessages={["تکرار کلمه عبور را وارد کنید"]}
                        />
                      </div>
                      <div style={{ width: "100%", height: 5 }} />
                      <div
                        style={{
                          padding: 4,
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#cc0000",
                          display: this.state.comparePswrd ? "block" : "none",
                        }}
                      >
                        کلمه عبور و تکرار آن یکسان نیست
                      </div>
                      <div style={{ marginTop: 10, marginBottom: 10 }}>
                        <label
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <span style={{ color: "#2B61CD", fontSize: "12px" }}>
                            شرایط عضویت را مطالعه و موافقت نمودم
                          </span>
                          <input
                            type="checkbox"
                            name="radioOk"
                            value="1"
                            style={{ marginLeft: 30, opacity: 0.6 }}
                            checked={this.state.terms}
                            onClick={() => this.setState({ termsOpen: true })}
                            onChange={(e) => this.onCheckBoxChanged(e)}
                          />
                        </label>
                      </div>
                      <TermsModal
                        visible={this.state.termsOpen}
                        onConfirm={() =>
                          this.setState({ terms: true, termsOpen: false })
                        }
                        onClose={() => this.setState({ termsOpen: false })}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <ColoredLine width="400px" color="#fefefe" />
                      </div>
                      <div style={{ marginBottom: 10 }}>
                        <span style={{ verticalAlign: "middle", fontSize: 14 }}>
                          مبلغ عضویت {this.state.membership} ریال
                        </span>
                      </div>
                      {this.state.resultMessage ? (
                        <div
                          style={{
                            padding: 10,
                            textAlign: "center",
                            color: "#aa0000",
                            fontWeight: "bold",
                          }}
                        >
                          {this.state.resultMessage}
                        </div>
                      ) : null}
                      <div style={{ width: "100%", height: 50 }} />
                      <div style={{ textAlign: "center" }}>
                        <Button
                          text= {this.state.membership === 0 ?"ادامه" :"پرداخت"}
                          type="submit"
                          classes={this.state.btnClassName}
                          disabled={this.state.btnStatus}
                          loading={this.state.showLoading}
                        />
                        <div style={{ width: 100, height: 10 }}></div>
                        <div>
                          <Button
                            text="بازگشت به صفحه ثبت نام"
                            type="submit"
                            classes="btnOutLineNormal"
                            onClickFn={this.goToSignup.bind(this)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </ValidatorForm>
        </div>
      </div>
    );
  }
}
