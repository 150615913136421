import React, { Component } from "react";
import Axios from "axios";
import API_URL from "../Api/ApiUrl";
import moment from "jalali-moment";
import { Card, CardBody } from "reactstrap";
import { ValidatorForm } from "react-form-validator-core";
import TextValidator from "../General/TextValidator";
import { isEmailAddress, isMobileNumber } from "../Utils/Utility";
import ButtonPlus from "../General/ButtonPlus";
import Button from "../General/Button";
import "../../App.css";

export class AddSeller extends Component {
  static displayName = AddSeller.name;

  form;
  name;
  family;
  mobile;
  email;
  password;
  constructor(props) {
    super(props);

    this.state = {
      form: {
        name: "",
        family: "",
        mobile: "",
        email: "",
        password: "",
      },

      sellerList: [],
      btnStatus: "",
      btnClassName: "btnBlueNormal",
      showSave: true,
      showLogin: false,
      resultMessage: "",
    };

    this.form = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    // console.log(this.props.location)
    ValidatorForm.addValidationRule("isMobileNumber", (value) =>
      isMobileNumber(value)
    );
    ValidatorForm.addValidationRule("isEmailAddress", (value) =>
      isEmailAddress(value)
    );
    //get seller list by ref id
    this.getSellersByRefId();
    this.getSellerCountByRefId();
  }

  getSellersByRefId() {
    // console.log(this.props.location.recordId)
    Axios.get(API_URL + "Seller/SellersByRefId/" + this.props.location.recordId)
      .then((response) => {
        this.setState({ sellerList: response.data });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ resultMessage: "خطا در ثبت اطلاعات" });
      });
  }
  
  getSellerCountByRefId() {
    // console.log(this.props.location.recordId)
    Axios.get(
      API_URL + "Seller/SellerCountByRefId/" + this.props.location.recordId
    )
      .then((response) => {
        var permitCount = Number(response.data.permit_count);
        // console.log(response)
        if (permitCount == 0) {
          this.setState({ resultMessage: "تعداد مجاز اکانت فروش: 0" });
          this.setState({ showSave: false });
          this.setState({ showLogin: true });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ resultMessage: "خطا در ثبت اطلاعات" });
      });
  }

  handleChange(event) {
    this.setState({ resultMessage: "" });
    this.setState({
      form: {
        ...this.state.form,
        [event.target.id]: event.target.value,
      },
    });
  }

  async handleSubmit() {
    // console.log(this.props.location)
    const RefNo = Number(this.props.location.recordId);
    const seller = {
      Id: 0,
      Name: this.state.form.name,
      Family: this.state.form.family,
      Mobile: this.state.form.mobile,
      Email: this.state.form.email,
      RefNo,
      RegDate: moment().locale("fa").format("YYYY/MM/DD"),
      Status: 0,
      password: this.state.form.password,
    };

    Axios.post(API_URL + "Seller", seller)
      .then((res) => {
        var msg = res.data.message;
        if (msg === "ok") {
          this.setState({ btnStatus: "disabled" });
          this.setState({ btnClassName: "btnBlueDisabled" });
          // this.setState({ resultMessage: 'ثبت اطلاعات با موفقیت انجام شد' });
          this.getSellersByRefId();
          this.getSellerCountByRefId();
        } else {
          this.setState({ resultMessage: "این ایمیل قبلاً ثبت شده است" });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ resultMessage: "خطا در ثبت اطلاعات" });
      });
  }

  doResetForm() {
    this.setState({
      form: {
        name: "",
        family: "",
        mobile: "",
        email: "",
        password: "",
      },
    });
    this.setState({ btnStatus: "" });
    this.setState({ btnClassName: "btnBlueNormal" });
  }

  goToLogin() {
    const { history } = this.props;
    history.push("/login");
  }

  handleBackClick = () => {
    this.props.history.goBack();
  };
  
  render(props) {
    
    const { sellerList } = this.state;
    const ColoredLine = ({ color, width }) => (
      <hr
        style={{
          color: color,
          backgroundColor: color,
          width: width,
          height: 1,
        }}
      />
    );
    return (
      <div className="outerCntnt">
        <div className="innerCntnt">
          <div
            style={{
              width: "100%",
              height: 620,
              textAlign: "right",
              paddingTop: 10,
            }}
          >
            <div
              style={{
                float: "right",
                width: 450,
                transform: "scale(0.9)",
                paddingLeft: 15,
              }}
            >
              <Card className="cardStyle auth-card">
                <CardBody>
                  <div
                    onClick={this.doResetForm.bind(this)}
                    style={{ direction: "rtl" }}
                  >
                    <ButtonPlus />
                  </div>
                  <div className="sellerContainer">
                    {sellerList.map((item) => {
                      return (
                        <div key={item.id}>{item.name + " " + item.family}</div>
                      );
                    })}
                  </div>
                  <div className="welButn" style={{ marginTop: 20 }}>
                    <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
                      <span className="labeled" style={{ fontSize: 13 }}>
                        نام
                      </span>
                      <TextValidator
                        type="text"
                        classes="textInputFa"
                        ref={this.name}
                        name="name"
                        id="name"
                        value={this.state.form.name}
                        handleChange={this.handleChange}
                        validators={["required", "maxStringLength:20"]}
                        errorMessages={[
                          "نام را وارد کنید",
                          "نام حداکثر باید 20 کاراکتر باشد",
                        ]}
                      />
                      <div style={{ width: "100%", height: 10 }} />
                      <span className="labeled" style={{ fontSize: 13 }}>
                        نام خانوادگی
                      </span>
                      <TextValidator
                        type="text"
                        classes="textInputFa"
                        ref={this.family}
                        name="family"
                        id="family"
                        value={this.state.form.family}
                        handleChange={this.handleChange}
                        validators={["required", "maxStringLength:30"]}
                        errorMessages={[
                          "نام خانوادگی را وارد کنید",
                          "نام خانوادگی حداکثر باید 30 کاراکتر باشد",
                        ]}
                      />
                      <div style={{ width: "100%", height: 10 }} />
                      <span className="labeled" style={{ fontSize: 13 }}>
                        شماره موبایل
                      </span>
                      <TextValidator
                        type="text"
                        classes="textInputEn"
                        ref={this.mobile}
                        name="mobile"
                        id="mobile"
                        value={this.state.form.mobile}
                        handleChange={this.handleChange}
                        validators={["required", "isMobileNumber"]}
                        errorMessages={[
                          "شماره موبایل را وارد کنید",
                          " شماره موبایل نا معتبر می باشد",
                        ]}
                      />
                      <div style={{ width: "100%", height: 10 }} />
                      <span className="labeled" style={{ fontSize: 13 }}>
                        ایمیل
                      </span>
                      <TextValidator
                        type="text"
                        classes="textInputEn"
                        ref={this.emailRef}
                        name="email"
                        id="email"
                        placeholder="sample@gmail.com"
                        value={this.state.form.email}
                        handleChange={this.handleChange}
                        validators={["required", "isEmailAddress"]}
                        errorMessages={[
                          "ایمیل را وارد کنید",
                          " آدرس ایمیل نا معتبر می باشد",
                        ]}
                      />
                      <div style={{ width: "100%", height: 10 }} />
                      <span className="labeled" style={{ fontSize: 13 }}>
                        پسورد
                      </span>
                      <TextValidator
                        type="password"
                        classes="textInputEn"
                        ref={this.passwordRef}
                        name="password"
                        id="password"
                        placeholder="*****"
                        value={this.state.form.password}
                        handleChange={this.handleChange}
                        validators={["required"]}
                        errorMessages={["کلمه عبور را وارد کنید"]}
                      />
                      {this.state.resultMessage ? (
                        <div
                          style={{
                            padding: 5,
                            textAlign: "center",
                            color: "#aa0000",
                            fontSize: "12px",
                          }}
                        >
                          {this.state.resultMessage}
                        </div>
                      ) : null}
                      <div
                        className="m-t-30 text-center"
                        style={{
                          display: this.state.showSave ? "flex" : "none",
                          flexDirection: "column",
                          alignItems: "center",
                          marginTop: 30,
                        }}
                      >
                        <Button
                          text="ثبت"
                          type="submit"
                          width="140px"
                          classes={this.state.btnClassName}
                          disabled={this.state.btnStatus}
                        />
                        <Button
                          text="بازگشت"
                          type="submit"
                          width="140px"
                          styles={{ marginTop: 10 }}
                          classes="btnOutLineNormal"
                          onClickFn={this.handleBackClick}
                        />
                      </div>
                      <div
                        className="m-t-30 text-center"
                        style={{
                          display: this.state.showLogin ? "block" : "none",
                        }}
                      >
                        <br />
                        <Button
                          text="ورود به حساب کاربری"
                          type="submit"
                          classes="btnOutLineNormal"
                          width="300px"
                          onClickFn={this.goToLogin.bind(this)}
                        />
                      </div>
                    </ValidatorForm>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
