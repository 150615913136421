import React, { Component } from "react";
import Axios from "axios";
import API_URL from "../Api/ApiUrl";
import { Card, CardBody } from "reactstrap";
import { ValidatorForm } from "react-form-validator-core";
import TextValidator from "../General/TextValidator";
import { isValidIranianNationalCode } from "../Utils/Utility";
import Button from "../General/Button";
import Icon from "../Icons/Icon";
import "../../App.css";

export class ConfirmPersonal extends Component {
  static displayName = ConfirmPersonal.name;

  form;
  nationalCode;
  identityNo;
  constructor(props) {
    super(props);

    this.state = {
      form: {
        nationalCode: "",
        identityNo: "",
      },
      signupTitle: "",
    };

    this.form = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    ValidatorForm.addValidationRule("isNationalCodeMatch", (value) =>
      isValidIranianNationalCode(value)
    );
    // console.log(this.props.location)
    switch (this.props.location.signupType) {
      case 1:
        this.setState({ signupTitle: "ثبت نام بازرگان" });
        break;
      case 2:
        this.setState({ signupTitle: "ثبت نام کریر" });
        break;
      case 3:
        this.setState({ signupTitle: "ثبت نام فورواردر" });
        break;
      default:
        this.setState({ signupTitle: "ثبت نام فورواردر" });
        break;
    }
    if(this.props.location.frwder ===1 ){
      this.setState({ signupTitle: "ثبت نام فورواردر" });
    }
  }

  handleChange(event) {
    this.setState({
      form: {
        ...this.state.form,
        [event.target.id]: event.target.value,
      },
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    const data = {
      Id: parseInt(this.props.location.merchantId),
      NationalNo: this.state.form.nationalCode,
      IdentityNo: this.state.form.identityNo,
      RegType: 1,
    };

    var signup_url = "";
    // console.log(this.props.location.signupType)
    switch (this.props.location.signupType) {
      case 1:
        signup_url = API_URL + "Merchant/CompleteMerchant/";
        break;
      case 2:
        signup_url = API_URL + "Provider/CompleteProvider/";
        break;
        case 3:
        signup_url = API_URL + "Provider/CompleteProvider/";
        break;
      default:
        signup_url = "";
        break;
    }

    Axios.put(signup_url + data.Id , data)
      .then((res) => {
        var actionType = this.props.location.action;
        if (actionType === "add") {
          this.props.history.push({
            pathname: "/addSeller",
            recordId: data.Id,
          });
        } else {
          var role_id = localStorage.getItem("roleId");
          if (role_id === "3") this.props.history.push("/carrier");
          else if (role_id === "4") this.props.history.push("/forwarder");
          else this.props.history.push("/login");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleBackClick = () => {
    this.props.history.goBack();
  };

  render() {
    const nationalCodeValidatorList = [
      "required",
      "isNumber",
      "maxStringLength:10",
      "isNationalCodeMatch",
    ];
    const nationalCodeValidators = nationalCodeValidatorList;
    const nationalCodeErrorMessages = [
      "کد ملی را وارد کنید",
      "لطفا عدد وارد کنید",
      " کد ملی حداکثر باید 10 کاراکتر باشد",
      "کد ملی معتبر نیست",
    ];
    const ColoredLine = ({ color, width }) => (
      <hr
        style={{
          color: color,
          backgroundColor: color,
          width: width,
          height: 1,
        }}
      />
    );
    return (
      <div className="outerCntnt">
        <div className="innerCntnt">
          <div
            style={{
              width: "100%",
              height: 600,
              textAlign: "right",
              paddingTop: 5,
              border: "0px solid #123456",
            }}
          >
            <div style={{ float: "right", width: 450, paddingLeft: 15 }}>
              <Card className="cardStyle dark-shadow auth-card rounded">
                <CardBody>
                  <div
                    style={{
                      marginBottom: 20,
                      marginTop: 20,
                      direction: "rtl",
                    }}
                  >
                    <span
                      style={{
                        verticalAlign: "middle",
                        fontSize: 13,
                        fontWeight: "bold",
                        display: "block",
                      }}
                    >
                      {this.state.signupTitle}
                    </span>
                    <span style={{ verticalAlign: "middle", fontSize: 14 }}>
                      لطفاً اطلاعات زیر را تکمیل نمایید:
                    </span>
                  </div>
                  <div className="welButn">
                    <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
                      <span className="labeled" style={{ fontSize: 13 }}>
                        کد ملی
                      </span>
                      <TextValidator
                        type="text"
                        classes="textInputEn"
                        ref={this.nationalCode}
                        name="nationalCode"
                        id="nationalCode"
                        value={this.state.form.nationalCode}
                        handleChange={this.handleChange}
                        validators={nationalCodeValidators}
                        errorMessages={nationalCodeErrorMessages}
                      />
                      <br />
                      <span className="labeled" style={{ fontSize: 13 }}>
                        شماره شناسنامه
                      </span>
                      <TextValidator
                        type="text"
                        classes="textInputEn"
                        ref={this.identityNo}
                        name="identityNo"
                        id="identityNo"
                        value={this.state.form.identityNo}
                        handleChange={this.handleChange}
                        validators={["required", "isNumber"]}
                        errorMessages={[
                          "شماره شناسنامه را وارد کنید",
                          "فقط عدد وارد کنید",
                        ]}
                      />
                      <div
                        className="m-t-30 text-center"
                        style={{ marginTop: 60 }}
                      >
                        <Button
                          text="ثبت"
                          type="submit"
                          classes="btnBlueNormal"
                        />
                      </div>
                      <div
                        className="m-t-30 text-center"
                        style={{ marginTop: 10 }}
                      >
                        <Button
                          text="بازگشت"
                          type="submit"
                          classes="btnOutLineNormal"
                          onClickFn={this.handleBackClick}
                        />
                      </div>
                    </ValidatorForm>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
