import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import { ValidatorForm } from "react-form-validator-core";
import TextValidator from "../General/TextValidator";
import { isMobileNumber } from "../Utils/Utility";
import Button from "../General/Button";
import Axios from "axios";
import API_URL from "../Api/ApiUrl";
import "../../App.css";

export class ForgetStep2 extends Component {
  static displayName = ForgetStep2.name;

  form;
  usernameRef;
  constructor(props) {
    super(props);

    this.state = {
      form: {
        username: "",
        password: "",
        password2: "",
      },
      resultMessage: "",
      showLoading: false,
    };

    this.form = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {}

  handleChange(event) {
    this.setState({ comparePswrd: false });
    this.setState({ errorMessage: "" });
    this.setState({
      form: {
        ...this.state.form,
        [event.target.id]: event.target.value,
      },
    });
  }

  async handleSubmit(event) {
    var pswrd1 = this.state.form.password;
    var pswrd2 = this.state.form.password2;

    if (pswrd1 != pswrd2) {
      this.setState({ comparePswrd: true });
      return;
    }

    this.setState({ showLoading: true });
    const reqParams = this.props.history.location.state;
    const mobileNumber = reqParams.mobileNumber;
    const permit = {
      Mobile: mobileNumber,
      Password: this.state.form.password,
    };

    Axios.put(API_URL + "User/setUserPermit", permit)
      .then((response) => {
        var msg = response.data.message;
        if (msg === "ok") {
          this.setState({ resultMessage: "تغییرات با موفقیت ثبت شد" });
        } else {
          this.setState({
            resultMessage: "کاربری با این شماره همراه پیدا نشد",
          });
        }

        this.setState({ showLoading: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ resultMessage: "خطا در دریافت اطلاعات" });
      });
  }

  goToSignup() {
    const { history } = this.props;
    history.push("/signup");
  }

  goToStart() {
    const { history } = this.props;
    history.push("/");
  }

  render() {
    return (
      <div className="outerCntnt">
        <div className="innerCntnt">
          <div style={{ width: "100%", textAlign: "right", paddingTop: 25 }}>
            <div style={{ float: "right", width: 450, paddingLeft: 15 }}>
              <Card className="cardStyle auth-card">
                <CardBody>
                  <div style={{ textAlign: "center", marginBottom: 20 }}>
                    <img
                      src={require("../../assets/images/padlock.png")}
                      width="60"
                      alt=""
                    />
                    <div style={{ width: 100, height: 10 }}></div>
                    <span style={{ fontSize: 12, fontWeight: "bold" }}>
                      بازنشانی رمز عبور
                    </span>
                    <div style={{ width: 100, height: 10 }}></div>
                    <span style={{ fontSize: 14 }}>
                      رمز عبور جدید خود را وارد کنید
                    </span>
                  </div>
                  <div className="welButn">
                    <ValidatorForm onSubmit={this.handleSubmit}>
                      <div className="formItem">
                        <span
                          style={{
                            verticalAlign: "middle",
                            fontSize: 14,
                            marginBottom: 2,
                            marginTop: 2,
                          }}
                        >
                          رمز عبور
                        </span>
                        <TextValidator
                          type="password"
                          classes="textInputEn"
                          ref={this.passwordRef}
                          name="password"
                          id="password"
                          placeholder="*****"
                          value={this.state.form.password}
                          handleChange={this.handleChange}
                          validators={["required"]}
                          errorMessages={["کلمه عبور را وارد کنید"]}
                        />
                      </div>
                      <div style={{ width: 100, height: 5 }}></div>
                      <div className="formItem">
                        <span
                          style={{
                            verticalAlign: "middle",
                            fontSize: 14,
                            marginBottom: 2,
                            marginTop: 5,
                          }}
                        >
                          تکرار رمز عبور
                        </span>
                        <TextValidator
                          type="password"
                          classes="textInputEn"
                          ref={this.password2}
                          name="password2"
                          id="password2"
                          value={this.state.form.password2}
                          handleChange={this.handleChange}
                          validators={["required"]}
                          errorMessages={["تکرار کلمه عبور را وارد کنید"]}
                        />
                      </div>
                      <div
                        style={{
                          padding: 4,
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#cc0000",
                          display: this.state.comparePswrd ? "block" : "none",
                        }}
                      >
                        کلمه عبور و تکرار آن یکسان نیست
                      </div>
                      {this.state.resultMessage ? (
                        <div
                          style={{
                            color: "#cc0000",
                            padding: 15,
                            textAlign: "center",
                          }}
                        >
                          {this.state.resultMessage}
                        </div>
                      ) : null}
                      <br />
                      <br />
                      <div className="m-t-30 text-center">
                        <Button
                          text="ثبت تغییر رمز عبور"
                          type="submit"
                          classes="btnBlueNormal"
                          loading={this.state.showLoading}
                        />
                      </div>
                      <div style={{ width: 100, height: 10 }}></div>
                      <div className="m-t-30 text-center">
                        <Button
                          text="بازگشت"
                          type="submit"
                          classes="btnOutLineNormal"
                          onClickFn={this.goToStart.bind(this)}
                        />
                      </div>
                    </ValidatorForm>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
