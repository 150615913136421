import React from "react";
import { Route, Redirect } from "react-router-dom";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const access = localStorage.getItem("access");
  const isAuth = sessionStorage.getItem("isLoggedIn");
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth && access ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
};

export const PublicRoute = ({ component: Component, ...rest }) => {
  const isAuth = sessionStorage.getItem("isLoggedIn");
  const userCode = localStorage.getItem("userCode");
  const roleType = userCode ? userCode.substring(0, 1) : null;

  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuth ? (
          <Component {...props} {...rest} />
        ) : roleType && roleType === "F" ? (
          <Redirect
            to={{
              pathname: "/forwarder",
            }}
          />
        ) : roleType && roleType === "C" ? (
          <Redirect
            to={{
              pathname: "/carrier",
            }}
          />
        ) : roleType &&
          (roleType === "M" ||
            roleType === "S" ||
            roleType === "A" ||
            roleType === "U") ? (
          <Redirect
            to={{
              pathname: "/merchant",
            }}
          />
        ) : (
          <Redirect
            to={{
              pathname: "/profile",
            }}
          />
        )
      }
    />
  );
};
