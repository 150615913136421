import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import { Redirect, Link } from "react-router-dom";
import "../../App.css";

export class Welcome extends Component {
  static displayName = Welcome.name;

  constructor(props) {
    super(props);

    this.state = {};
  }

  goToPage = (pageId) => {
    switch (pageId) {
      case 1:
        this.props.history.push("/account");
        break;
      case 2:
        this.props.history.push("/login");
        break;
      case 3:
        this.props.history.push("/signup");
        break;
      default:
        this.props.history.push("/");
    }
  };

  handleLogoClick = () => {
    return <Redirect to="/" push={true} />;
  };

  render() {
    return (
      <div className="outerCntnt welcome">
        <div className="imageCntnt">
          <img src={require("../../assets/images/welcome-banner.jpg")} alt="" />
        </div>
        <div className="innerCntnt">
          <img
            src={require("../../assets/images/earthmap@2x.png")}
            className="welcome-background"
            alt=""
          />
          <div className="welcome-content">
            <div className="welcome-logo">
              <a href="/" onClick={this.handleLogoClick}>
                <img
                  src={require("../../assets/images/volgalogo120.png")}
                  width="120"
                  alt="Volga"
                  style={{ cursor: "pointer" }}
                />
              </a>
            </div>
            <div>
              <div className="appTitle">به ولگا خوش آمدید</div>
            </div>
            <div>
              <span className="appSubTitle" style={{ verticalAlign: "middle" }}>
                اولین پلتفرم ارتباط مؤثر کریر، فورواردر و بازرگانان ایران برای
                دسترسی به بهترین سرویس و کرایه حمل ممکن
              </span>
            </div>
            <div className="welcome-boxes-wrapper">
              <Card className="butnCard" onClick={() => this.goToPage(1)}>
                <CardBody>
                  <div className="welButn">
                    <img
                      src={require("../../assets/images/icon_search.png")}
                      width="90"
                      alt=""
                    />
                    <br />
                    <span
                      style={{
                        color: "#151a6a",
                        fontSize: 15,
                        fontWeight: "bold",
                      }}
                    >
                      اکانت یک روزه رایگان
                    </span>
                  </div>
                </CardBody>
              </Card>
              <Card className="butnCard" onClick={() => this.goToPage(2)}>
                <CardBody>
                  <div className="welButn">
                    <img
                      src={require("../../assets/images/icon_login.png")}
                      width="90"
                      alt=""
                    />
                    <br />
                    <span
                      style={{
                        color: "#ffb509",
                        fontSize: 16,
                        fontWeight: "bold",
                      }}
                    >
                      ورود
                    </span>
                  </div>
                </CardBody>
              </Card>
              <Card className="butnCard" onClick={() => this.goToPage(3)}>
                <CardBody>
                  <div className="welButn">
                    <img
                      src={require("../../assets/images/icon_signup.png")}
                      width="90"
                      alt=""
                    />
                    <br />
                    <span
                      style={{
                        color: "#165df5",
                        fontSize: 16,
                        fontWeight: "bold",
                      }}
                    >
                      ثبت نام
                    </span>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="welcome-bottom-area">
              <div className="links">
                <Link to="/about">درباره ما</Link>
                <Link to="/contact">تماس با ما</Link>
                <Link to="/why-volga">چرا ولگا؟</Link>
              </div>
              <p>
                کلیه حقوق و امتیازات این وبسایت متعلق به لنگرگاه طلایی پارس
                می‌باشد.
              </p>
              <div className="namads">
                <a
                  referrerPolicy="origin"
                  target="_blank"
                  href="https://trustseal.enamad.ir/?id=228030&amp;Code=hfvxu54N9JCZ1eLHoUOO"
                >
                  <img
                    referrerPolicy="origin"
                    src="https://trustseal.eNamad.ir/logo.aspx?id=228030&amp;Code=hfvxu54N9JCZ1eLHoUOO"
                    alt=""
                    style={{
                      cursor: "pointer",
                    }}
                    id="hfvxu54N9JCZ1eLHoUOO"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
